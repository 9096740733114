/**
 * Đây là component tổ hợp chung cho 3 button thêm mới, import, export
 *
 * @param {string} keySide - Đây là dmDichVuId
 * @param {string} dichVu - Đây là tên dịch vụ
 *
 * @returns {JSX.Element}
 */

import React, { useState } from "react";
import { Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { DownloadOutlined, ImportOutlined } from "@ant-design/icons";
import axios from "axios";
import ModalImportFile from "../modalImportFile/ModalImportFile";
import authHeader from "../../../../api/auth_header";
import { API_BASE_URL } from "../../../../constants";

const formActionButtonConfig = {
    hotel: {
        type: "DS_KHACH_SAN",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/khach-san/new",
        nameFile: "fileName.listhotel",
    },
    oto: {
        type: "DS_OTO",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/oto/new",
        nameFile: "fileName.listcar",
    },
    restaurant: {
        type: "DS_NHA_HANG",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/nha-hang/new",
        nameFile: "fileName.listRes",
    },
    yacht: {
        type: "DS_DU_THUYEN",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/du-thuyen/new",
        nameFile: "fileName.listYacht",
    },
    tourGuide: {
        type: "DS_HDV_CONG_TY",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/huong-dan-vien/new",
        nameFile: "fileName.listTourGuide",
    },
    plane: {
        type: "DS_VE_MAY_BAY",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/may-bay/new",
        nameFile: "fileName.listPlane",
    },
    boatTickets: {
        type: "DS_VE_THUYEN",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/ve-thuyen/new",
        nameFile: "fileName.listBoatTicket",
    },
    "visa-ttc": {
        type: "DS_VISA",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/visa/new",
        nameFile: "fileName.listVisa",
    },
    scenicTickets: {
        type: "DS_VE_THANG_CANH",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/ve-thang-canh/new",
        nameFile: "fileName.listScenicTickets",
    },
    busTickets: {
        type: "DS_VE_BUS",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/ve-bus/new",
        nameFile: "fileName.busTickets",
    },
    trainTickets: {
        type: "DS_VE_TAU",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/ve-tau/new",
        nameFile: "fileName.trainTickets",
    },
    otherServices: {
        type: "DS_DICH_VU_KHAC",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/dich-vu-khac/new",
        nameFile: "fileName.otherServices",
    },
    landTour: {
        type: "DS_LAND_TOUR",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/land-tour/new",
        nameFile: "fileName.landTour",
    },
    freelanceTourGuide: {
        type: "DS_HDV_TU_DO",
        urlExport: "ncc/files/export/excel",
        navigateUrl: "/ncc/huong-dan-vien-tu-do/new",
        nameFile: "fileName.freelanceTourGuide",
    },
};

const getformActionButtonConfig = (key) => {
    return formActionButtonConfig[key];
};

const FormActionButtonsFirst = ({ keySide, dichVu }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { type, urlExport, navigateUrl, nameFile } =
        getformActionButtonConfig(dichVu);

    const [isModalOpenUpload, setIsModalOpenUpload] = useState(false);

    const showModalUpload = () => {
        setIsModalOpenUpload(true);
    };
    const handleCancelUpload = () => {
        setIsModalOpenUpload(false);
    };
    const handleFinishUpload = () => {
        setIsModalOpenUpload(false);
    };

    const onClickAdd = () => {
        return navigate(navigateUrl);
    };

    const handleExportFile = () => {
        const formData = new FormData();
        formData.append("type", type);

        axios({
            method: "post",
            url: API_BASE_URL + urlExport,
            headers: {
                ...authHeader(),
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
            .then((response) => {
                const { data } = response.data;
                if (!data) {
                    console.error("No data found in response!");
                    return;
                }

                const binaryData = atob(data);
                const arrayBuffer = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    arrayBuffer[i] = binaryData.charCodeAt(i);
                }

                const blob = new Blob([arrayBuffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                const getNameFile = t(nameFile);
                link.setAttribute(
                    "download",
                    `${getNameFile.toLocaleUpperCase()}.xlsx`
                );
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Download failed: ", error);
            });
    };

    // this is props of modal import file
    const title =
        t("addNewInfo") +
        " " +
        t(dichVu).toLocaleLowerCase() +
        " " +
        t("fromexcel");

    const noteList = [
        t(`note.${dichVu}.1`) === `note.${dichVu}.1`
            ? t("note.add.1")
            : t(`note.${dichVu}.1`),
        t("note.add.2"),
        t("note.add.3"),
        t("note.add.4"),
        t("note.add.5") + " " + t(dichVu).toLocaleLowerCase(),
    ];

    return (
        <Row>
            <Space
                size="small"
                style={{
                    marginTop: "5px",
                }}
            >
                <Button
                    variant="outlined"
                    sx={{ whiteSpace: "nowrap" }}
                    startIcon={<DownloadOutlined />}
                    onClick={handleExportFile}
                >
                    {t("exportFile")}
                </Button>
                <Button
                    sx={{ whiteSpace: "nowrap" }}
                    variant="outlined"
                    startIcon={<ImportOutlined />}
                    onClick={showModalUpload}
                    type="dashed"
                >
                    {t("importFile")}
                </Button>
                <Button
                    variant="outlined"
                    onClick={onClickAdd}
                    startIcon={<AddIcon />}
                    color="warning"
                >
                    {t("add")}
                </Button>
            </Space>
            <ModalImportFile
                isOpen={isModalOpenUpload}
                handleCancel={handleCancelUpload}
                handleFinish={handleFinishUpload}
                keySide={keySide}
                title={title}
                noteList={noteList}
                name={dichVu}
            />
        </Row>
    );
};
export default FormActionButtonsFirst;
