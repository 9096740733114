import React, { useEffect, useState, useRef } from "react";
import {
    Table,
    Typography,
    Switch,
    notification,
    Space,
    Popconfirm,
    Input,
    Button,
    Row,
    Col,
} from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import FormPRNH from "./FormPRNH";
import Highlighter from "react-highlight-words";
import { API_BASE_URL, TIME_DURATION } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import ModalPrAction from "./ModalPrAction";
import { DataGrid, viVN, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const DvNhaHang = ({
    dvName,
    dvId,
    isView,
    QuickSearchToolbar,
    isDichVuKhac,
    reloadNew,
}) => {
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    // const [dataSearch, setDataSearch] = useState();
    const isEditing = (record) => record.key === editingKey;
    const [api, contextHolder] = notification.useNotification();
    const [reload, setReload] = useState(false);
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };

    useEffect(() => {
        if (dvId || reload || reloadNew) {
            getData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dvId, reload, reloadNew]);

    const getData = () => {
        let requestData = {
            nccThongTinChungId: dvId,
            isDichVuKhac: isDichVuKhac,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvNhaHang/getData",
            data: requestData,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response dvNhaHang: ====", response.data);
                if (response.status === 200) {
                    setReload(false);
                    let responseData = response.data.data;
                    let dataLst = [];
                    let dataLstSearch = [];

                    responseData.forEach((item, index) => {
                        let giaPhongDetail =
                            item.tranxDvNhaHangTimeRequests || [];

                        if (giaPhongDetail.length > 0) {
                            giaPhongDetail.forEach((detail) => {
                                dataLst.push({
                                    stt: dataLst.length + 1,
                                    rowKey: 1 + Math.random() * (100 - 1),
                                    ...item,
                                    ...detail,
                                    ids: item.id,
                                    dvNHid: detail.id,
                                    thoiGianApDung:
                                        detail.tuNgay + " - " + detail.denNgay,
                                    conditionFoc:
                                        detail.dieuKienFoc +
                                        " " +
                                        t("slot") +
                                        " / " +
                                        detail.foc +
                                        " " +
                                        "FOC",
                                    isDouble: true,
                                });
                            });
                        } else {
                            dataLst.push({
                                stt: dataLst.length + 1,
                                rowKey: index + 1,
                                ...item,
                                isDouble: false,
                            });
                        }

                        dataLstSearch.push({
                            value: item.id - item.maDichVu - item.tenDichVu,
                        });
                    });
                    // debugger;
                    // setDataSearch(dataLstSearch);
                    setDataSource(dataLst);
                }
            })
            .catch(function (error) {
                console.error("Error response: ====", error);
                if (error.response && error.response.status === 401) {
                    // Handle unauthorized error
                }
            });
    };

    const handleBorderChange = (record, value) => {
        let values = {
            id: record.dvNHid,
            trangThaiBooking: value !== true,
            hieuLuc: record.hieuLuc,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvNhaHang/updateStatusTranxTime",
            data: values,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    setReload(true);
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:
                            t("edit") +
                            " " +
                            t("noti.error").toLowerCase() +
                            " " +
                            response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });

        // setDataSource(newData);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(false);
    };
    const handleDelete = (record) => {
        let apiData = {
            id: record.ids,
            hieuLuc: false,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvNhaHang/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    setReload(true);
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:
                            t("delete") +
                            " " +
                            t("noti.error").toLowerCase() +
                            " " +
                            response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    message: t("stour"),
                    description:
                        t("delete") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });
    };
    const handleCancel = () => {
        setEditingRecord("");
        setIsShow(false);
    };
    const handleFinish = async (status, values) => {
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
            isDichVuKhac: isDichVuKhac,
        };
        try {
            let response = await axios({
                method: "post",
                url: API_BASE_URL + "ncc/dvNhaHang/saveData",
                data: data,
                headers: authHeader(),
            });
            if (response.status === 200 && response.data.code === "00") {
                setIsShow(false);
                setAction(status);
                getData();
                api["success"]({
                    message: t("stour"),
                    description:
                        t("edit") + " " + t("noti.success").toLowerCase(),
                    duration: TIME_DURATION,
                });
                return true;
            } else {
                setAction(true);
                api["error"]({
                    message: t("stour"),
                    description: response.data.message,
                    duration: TIME_DURATION,
                });
                return false;
            }
        } catch (error) {
            console.log("Error response: ====" + error);
            api["error"]({
                message: t("stour"),
                description: t("edit") + " " + t("noti.error").toLowerCase(),
                duration: TIME_DURATION,
            });
            return false;
        }
    };

    const maPhongs = new Set();
    const stts = new Set();
    const hangPhongs = new Set();
    useEffect(() => {
        // https://stackblitz.com/edit/react-bubauz?file=index.js
        // https://stackoverflow.com/questions/61285411/ant-design-table-row-merge-based-on-data-instead-of-index-value
        maPhongs.clear();
        stts.clear();
        hangPhongs.clear();
    }, [hangPhongs, maPhongs, stts]);
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            headerAlign: "center",
            align: "center",
            // renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
        },
        {
            headerName: t("res.code"),
            field: "maDichVu",
            key: "maDichVu",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("res.serviceProvided"),
            field: "tenDichVu",
            key: "tenDichVu",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.priceRoom.applicationTime"),
            field: "thoiGianApDung",
            key: "thoiGianApDung",
            width: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            headerAlign: "center",
            field: "fitNgayThuongNet",
            key: "fitNgayThuongNet",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            headerAlign: "center",
            field: "fitNgayThuongGiaBan",
            key: "fitNgayThuongGiaBan",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            headerAlign: "center",
            field: "fitCuoiTuanNet",
            key: "fitCuoiTuanNet",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            headerAlign: "center",
            field: "fitCuoiTuanGiaBan",
            key: "fitCuoiTuanGiaBan",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.roomClass.conditionFoc"),
            headerAlign: "center",
            field: "conditionFoc",
            key: "conditionFoc",
            flex: 1,
            align: "center",
        },
        {
            headerName: t("exchangeRate.currency"),
            field: "loaiTien",
            key: "loaiTien",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("status"),
            field: "trangThaiBooking",
            key: "trangThaiBooking",
            flex: 1,
            headerAlign: "center",
            align: "center",
            filters: [
                {
                    text: "True",
                    value: true,
                },
                {
                    text: "False",
                    value: false,
                },
            ],
            renderCell: ({ value, row }) => {
                if (value !== undefined) {
                    return (
                        <Switch
                            defaultChecked={value}
                            onChange={() => handleBorderChange(row, value)}
                        />
                    );
                } else {
                    return null;
                }
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space>
                        <Typography.Link>
                            <EyeOutlined
                                onClick={() => viewRecord(record.row)}
                            />
                        </Typography.Link>
                        <Typography.Link
                            disabled={editingKey !== ""}
                            onClick={() => edit(record.row)}
                        >
                            <EditOutlined style={{}} />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];
    const columnGroupingModel = [
        {
            groupId: t("hotel.priceClass.weekdayPrice"),

            children: [
                { field: "fitNgayThuongNet" },
                { field: "fitNgayThuongGiaBan" },
            ],
        },
        {
            groupId: t("hotel.priceClass.weekendPrice"),
            children: [
                { field: "fitCuoiTuanNet" },
                { field: "fitCuoiTuanGiaBan" },
            ],
        },
    ];

    return (
        <div>
            {contextHolder}
            <DataGrid
                sx={{
                    "& .MuiDataGrid-columnSeparator--sideRight": {
                        display: "none",
                    },
                    "& .MuiDataGrid-columnSeparator--sideLeft": {
                        display: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#e5e5e5",
                        fontWeight: "bold",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        borderRight: "1px solid #ddd",
                    },
                    "& .MuiDataGrid-cell": {
                        borderRight: "1px solid #eee",
                        borderBottom: "1px solid #eee",
                        "&:hover": {
                            backgroundColor: "#fafafa",
                        },
                    },
                    "& .MuiDataGrid-row:hover": {
                        backgroundColor: "#f1f1f1",
                    },
                    "& .MuiDataGrid-row.Mui-selected": {
                        backgroundColor: "#d3e5ff !important",
                    },
                    "& .MuiDataGrid-row.editing": {
                        backgroundColor: "#fffbcc",
                    },
                    "& .MuiDataGrid-columnSeparator": {
                        visibility: "visible",
                        color: "#ccc",
                    },
                    "& .MuiDataGrid-cell:focus": {
                        outline: "none",
                    },
                }}
                onRowDoubleClick={(record) => edit(record.row)}
                rows={dataSource}
                columns={columns}
                experimentalFeatures={{ columnGrouping: true }}
                slots={{
                    toolbar: QuickSearchToolbar,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[5, 10, 25]}
                {...dataSource}
                columnGroupingModel={columnGroupingModel}
                autoHeight
                localeText={viVN.components.MuiDataGrid.defaultProps.localeText}
            />
            <ModalPrAction
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
            />
        </div>
    );
};
export default DvNhaHang;
