import TableMui from "../../../../layout/TableMui";
import React, { useEffect, useState } from "react";
import { notification, Popconfirm, Space, Switch, Typography } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_BASE_URL, TIME_DURATION } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import ModalPrAction from "./ModalPrAction";

const TableDvccOto = ({
    isDichVuKhac,
    dvId,
    QuickSearchToolbar,
    reloadNew,
}) => {
    const { t } = useTranslation();
    const [dataSource, setDataSource] = useState([]);
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const isEditing = (record) => record.key === editingKey;
    const [api, contextHolder] = notification.useNotification();
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
    };

    useEffect(() => {
        getData();
    }, [dvId, reloadNew]);

    const getData = () => {
        let data = {
            nccThongTinChungId: dvId,
            isDichVuKhac: isDichVuKhac,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvOto/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    if (data.length > 0) {
                        let sttCounter = 1;
                        let dataLst = data.flatMap((item, index) => {
                            const commonFields = {
                                id: item.id,
                                nccThongTinChungId: item.nccThongTinChungId,
                                maDichVu: item.maDichVu,
                                tenDichVu: item.tenDichVu,
                                dmTyGiaId: item.dmTyGiaId,
                                maNgoaiTe: item.maNgoaiTe,
                                loaiTien: item.loaiTien,
                                ghiChu: item.ghiChu,
                                dmHinhThucGiaId: item.dmHinhThucGiaId,
                                hinhThucGia: item.hinhThucGia,
                                dmSoChoId: item.dmSoChoId,
                                soCho: item.soCho,
                                dmLoaiXeId: item.dmLoaiXeId,
                                tenLoaiXe: item.tenLoaiXe,
                                isDouble:
                                    item.tranxDvOtoTimeRequests.length > 0,
                            };

                            // Nếu có chi tiết dịch vụ
                            if (item.tranxDvOtoTimeRequests.length > 0) {
                                return item.tranxDvOtoTimeRequests.map(
                                    (detail) => ({
                                        rowKey: 1 + Math.random() * (100 - 1),
                                        stt: sttCounter++,
                                        ...commonFields,
                                        ids: detail.id,
                                        dvoToId: detail.id,
                                        nccDichVuOtoId: detail.nccDichVuOtoId,
                                        thoiGianApDung: `${detail.tuNgay} - ${detail.denNgay}`,
                                        tuNgay: detail.tuNgay,
                                        denNgay: detail.denNgay,
                                        giaNet: detail.giaNet,
                                        giaBan: detail.giaBan,
                                        trangThaiBooking:
                                            detail.trangThaiBooking,
                                        hieuLuc: detail.hieuLuc,
                                    })
                                );
                            }

                            // Nếu không có chi tiết dịch vụ
                            return [
                                {
                                    stt: sttCounter++, // Tăng số thứ tự cho mục chính
                                    ...commonFields,
                                },
                            ];
                        });

                        // Tạo danh sách tìm kiếm
                        let dataLstSearch = data.map((item) => ({
                            value: `${item.id}-${item.maDichVu}-${item.tenDichVu}`,
                        }));

                        setDataSource(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };

    // const handleChange = (pagination, filters, sorter) => {
    //     console.log("Various parameters", pagination, filters, sorter);
    //     setFilteredInfo(filters);
    // };
    const handleBorderChange = (record, value) => {
        let values = {
            id: record.dvoToId,
            trangThaiBooking: value !== true,
            hieuLuc: record.hieuLuc,
        };
        console.log("handleBorderChange " + JSON.stringify(values));
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvOto/updateStatusTranxTime",
            data: values,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });

        // setDataSource(newData);
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(false);
    };
    const handleDelete = (record) => {
        const newData = dataSource.filter((item) => item.id !== record.id);
        let apiData = {
            id: record.id,
            hieuLuc: false,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/dvOto/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:
                            t("delete") +
                            " " +
                            t("noti.error").toLowerCase() +
                            " " +
                            response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch((error) => {
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });

        setDataSource(newData);
    };
    const handleCancel = () => {
        setIsShow(false);
    };
    const handleFinish = async (status, values) => {
        let data = {
            ...values,
            id: values.id, // thêm mới để 0, update truyền id đúng vào
            isDichVuKhac: isDichVuKhac,
        };
        // console.log("data edit dv oto=====", data);
        try {
            let response = await axios({
                method: "post",
                url: API_BASE_URL + "ncc/dvOto/saveData",
                data: data,
                headers: authHeader(),
            });
            if (response.status === 200 && response.data.code === "00") {
                setIsShow(false);
                setAction(status);
                getData();
                api["success"]({
                    message: t("stour"),
                    description:
                        t("edit") + " " + t("noti.success").toLowerCase(),
                    duration: TIME_DURATION,
                });
                return true;
            } else {
                setAction(true);
                api["error"]({
                    message: t("stour"),
                    description: response.data.message,
                    duration: TIME_DURATION,
                });
                return false;
            }
        } catch (error) {
            console.log("Error response: ====" + error);
            api["error"]({
                message: t("stour"),
                description: t("edit") + " " + t("noti.error").toLowerCase(),
                duration: TIME_DURATION,
            });
            return false;
        }
    };

    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            headerAlign: "center",
            align: "center",
            // renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1,
        },
        {
            headerName: t("res.code"),
            field: "maDichVu",
            key: "maDichVu",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("res.serviceProvided") + "/ " + t("trip"),
            field: "tenDichVu",
            key: "tenDichVu",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("formOfPricing"),
            field: "hinhThucGia",
            key: "hinhThucGia",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("number"),
            field: "soCho",
            key: "soCho",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("vehicleType"),
            field: "tenLoaiXe",
            key: "tenLoaiXe",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.priceRoom.applicationTime"),
            field: "thoiGianApDung",
            key: "thoiGianApDung",
            width: 200,
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.priceClass.giaNet"),
            field: "giaNet",
            key: "giaNet",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("hotel.priceClass.giaBan"),
            field: "giaBan",
            key: "giaBan",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return params.value.toLocaleString();
            },
        },
        {
            headerName: t("exchangeRate.currency"),
            field: "loaiTien",
            key: "loaiTien",
            flex: 1,
            headerAlign: "center",
            align: "center",
            width: 200,
        },
        {
            headerName: t("status"),
            field: "trangThaiBooking",
            key: "trangThaiBooking",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: ({ value, row }) => {
                if (value !== undefined) {
                    return (
                        <Switch
                            defaultChecked={value}
                            onChange={() => handleBorderChange(row, value)}
                        />
                    );
                } else {
                    return null;
                }
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space>
                        <Typography.Link>
                            <EyeOutlined
                                onClick={() => viewRecord(record.row)}
                            />
                        </Typography.Link>
                        <Typography.Link
                            disabled={editingKey !== ""}
                            onClick={() => edit(record.row)}
                        >
                            <EditOutlined style={{}} />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];

    return (
        <div>
            {contextHolder}
            <TableMui
                dataSource={dataSource}
                columns={columns}
                edit={edit}
                QuickSearchToolbar={QuickSearchToolbar}
            />
            {isShow && (
                <ModalPrAction
                    isOpen={isShow}
                    handleCancel={handleCancel}
                    handleFinish={handleFinish}
                    record={editingRecord}
                    action={action}
                    dvId={editingRecord?.id}
                />
            )}
        </div>
    );
};
export default TableDvccOto;
