import React, {useEffect} from "react";
import {Row} from "antd";
import NhaHang from "./dichVu/NhaHang";
import TongKet from "./TongKet";
import {useTranslation} from "react-i18next";
import VanChuyenNew from "./dichVu/VanChuyenNew";
import KhachSanNew from "./dichVu/KhachSanNew";
import DichVuCommon from "./dichVu/DichVuCommon";

const ChildrenTab = ({
                         dataSource,
                         changeTk,
                         tyGia,
                         onReset,
                         keyTab,
                         tourThongTinChungId,
                         tourThoiGianApDungId,
                         tourChietTinhId,
                         tabChietTinhId,
                         tongSoNgay,
                         loaiTong,
                         tourQuanLyKhoangKhachId
                     }) => {
    const {t} = useTranslation();
    const [tyGiaTab, setTyGiaTab] = React.useState(tyGia);
    const handleChangeTk = (value) => {
        changeTk(value);
    };
    useEffect(() => {
        setTyGiaTab(tyGia);
    }, [tyGia]);
    return (
        <div>
            <Row>
                <VanChuyenNew
                    dataSource={dataSource.vanChuyenValues}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongVanChuyen={dataSource.tongVanChuyen}
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQLkcID={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                />
            </Row>
            <Row style={{marginTop: "2%"}}>
                <KhachSanNew
                    dataSource={dataSource.khachSanResponse}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongChietTinhKhachSanResponse={
                        dataSource.tongChietTinhKhachSanResponse
                    }
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                />
            </Row>
            <Row style={{marginTop: "2%"}}>
                <NhaHang
                    dataSource={dataSource.nhaHangResponse}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongChietTinhNhaHangResponse={dataSource.tongChietTinhNhaHangResponse}
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                />
            </Row>
            <Row style={{marginTop: "2%"}}>
                <DichVuCommon
                    dataSource={dataSource.huongDanVienResponse || []}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongChietTinhResponse={dataSource.tongChietTinhHdvResponse}
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                    tranxChietTinhId={"tranxHdvId"}
                    chietTinh={"huongDanVien"}
                    stt={"5"}
                    title={t("tourGuide")}
                />
            </Row>
            <Row style={{marginTop: "2%"}}>
                <DichVuCommon
                    dataSource={dataSource.veMayBayResponse || []}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongChietTinhResponse={dataSource.tongChietTinhVeMayBayResponse}
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                    tranxChietTinhId={"tranxMayBayId"}
                    chietTinh={"veMayBay"}
                    stt={"6"}
                    title={t("planeTickets")}
                />
            </Row>
            <Row style={{marginTop: "2%"}}>
                <DichVuCommon
                    dataSource={dataSource.veThuyenResponse || []}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongChietTinhResponse={dataSource.tongChietTinhVeThuyenResponse}
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                    tranxChietTinhId={"tranxVeThuyenId"}
                    chietTinh={"veThuyen"}
                    stt={"7"}
                    title={t("boatTickets")}
                />
            </Row>
            <Row style={{marginTop: "2%"}}>
                <DichVuCommon
                    dataSource={dataSource.visaResponse || []}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongChietTinhResponse={dataSource.tongChietTinhVisaResponse}
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                    tranxChietTinhId={"tranxVisaId"}
                    chietTinh={"visa"}
                    stt={"8"}
                    title={t("visa")}
                />
            </Row>
            <Row style={{marginTop: "2%"}}>
                <DichVuCommon
                    dataSource={dataSource.veThangCanhResponse || []}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongChietTinhResponse={dataSource.tongChietTinhVeThangCanhResponse}
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                    tranxChietTinhId={"tranxVeThangCanhId"}
                    chietTinh={"veThangCanh"}
                    stt={"9"}
                    title={t("scenicTickets")}
                />
            </Row>
            <Row style={{marginTop: "2%"}}>
                <DichVuCommon
                    dataSource={dataSource.veBusResponse || []}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongChietTinhResponse={dataSource.tongChietTinhVeBusResponse}
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                    tranxChietTinhId={"tranxBusId"}
                    chietTinh={"veBus"}
                    stt={"10"}
                    title={t("busTickets")}
                />
            </Row>
            <Row style={{marginTop: "2%"}}>
                <DichVuCommon
                    dataSource={dataSource.veTauResponse || []}
                    tongSoNgay={tongSoNgay}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    tongChietTinhResponse={dataSource.tongChietTinhVeTauResponse}
                    tyGia={tyGiaTab}
                    onReset={onReset}
                    tourChuongTrinh={dataSource.tourChuongTrinh}
                    activeKey={keyTab}
                    tourThongTinChungId={tourThongTinChungId}
                    tourThoiGianApDungId={tourThoiGianApDungId}
                    tourChietTinhId={tourChietTinhId}
                    tabChietTinhId={tabChietTinhId}
                    loaiTong={loaiTong}
                    tourQuanLyKhoangKhachId={tourQuanLyKhoangKhachId}
                    thoiGianApDung={dataSource.thoiGianApDung}
                    tranxChietTinhId={"tranxVeTauId"}
                    chietTinh={"veTau"}
                    stt={"11"}
                    title={t("trainTickets")}
                />
            </Row>
            <Row style={{marginTop: "2%"}}>
                <TongKet
                    tongChietTinhResponses={dataSource.tongChietTinhResponses}
                    danhSachKhoangKhach={dataSource.danhSachKhoangKhach}
                    changeTongKet={handleChangeTk}
                    tyGia={tyGiaTab}
                    activeKey={keyTab}
                />
            </Row>
        </div>
    );
};
export default ChildrenTab;
