import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const getCauHinhVeMayBayTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/layCauHinhMayBayTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const updateCauHinhVeMayBayTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/updateCauHinhMayBayTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const saveCauHinhVeMayBay = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/saveCauHinhMayBay", data, {
    headers: authHeader(),
  });
};

const getQuocGia = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachQuocGia", data, {
    headers: authHeader(),
  });
};

const getTinhTP = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachTinhThanhPho", data, {
    headers: authHeader(),
  });
};

const getNccVeMayBay = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getNccMayBay", data, {
    headers: authHeader(),
  });
};

const xoaCauHinhVeMayBay = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/xoaCauHinhMayBay", data, {
    headers: authHeader(),
  });
};

export default {
  getCauHinhVeMayBayTheoMap,
  updateCauHinhVeMayBayTheoMap,
  saveCauHinhVeMayBay,
  getQuocGia,
  getTinhTP,
  getNccVeMayBay,
  xoaCauHinhVeMayBay,
};
