import React, { useEffect, useState, useRef } from "react";

import {
    Button,
    Input,
    notification,
    Popconfirm,
    Space,
    Row,
    Col,
    Typography,
    Switch,
} from "antd";
import {
    DataGrid,
    GridToolbar,
    viVN,
    enUS,
    GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import i18n from "../../../i18n";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { API_BASE_URL, TIME_DURATION } from "../../../constants";
import authHeader from "../../../api/auth_header";
import {
    DeleteOutlined,
    EditOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router-dom";
import TableMui from "../../layout/TableMui";
import FormActionButtonsFirst from "../common/formActionButton/FormActionButtonsFirst";

const SpVeThuyen = ({ keySide, nameKey }) => {
    const [dataSource, setDataSource] = useState([]);
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    const isEditing = (record) => record.key === editingKey;
    const [filteredInfo, setFilteredInfo] = useState({});
    const [dataSearch, setDataSearch] = useState();

    const navigate = useNavigate();
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
        direct(record, "edit");
    };

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    useEffect(() => {
        if (keySide !== undefined && keySide.toString().length > 0) {
            console.log("key===", keySide);
            let data = {
                dmDichVuId: keySide,
            };
            axios({
                method: "post",
                url: API_BASE_URL + "nnc/thongtinchung/getData",
                data: data,
                headers: authHeader(),
            })
                .then(function (response) {
                    console.log("Response: ====" + response.status);
                    if (response.status === 200) {
                        let data = response.data.data;
                        // let dataArr = [];
                        // let dataLstSearch = [];
                        // setDataSearch(dataLstSearch);
                        setDataSource(data);
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
    }, [keySide]);
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
        }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`${t("search")}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(selectedKeys, confirm, dataIndex)
                    }
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(selectedKeys, confirm, dataIndex)
                        }
                        icon={<SearchOutlined />}
                        size="small"
                    >
                        {t("search")}
                    </Button>
                    <Button
                        onClick={() =>
                            clearFilters && handleReset(clearFilters)
                        }
                        size="small"
                    >
                        {t("action.reset")}
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        {t("close")}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1677ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };
    const direct = (record, status) => {
        switch (keySide) {
            case 7:
                return navigate(
                    "/ncc/ve-thuyen/" +
                        status +
                        "?id=" +
                        record.id +
                        "&key=" +
                        keySide
                );
            default:
                return null;
        }
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        direct(record, "view");
    };

    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            width: 60,
            headerAlign: "center",
            align: "center",
            renderCell: (params) =>
                params.api.getAllRowIds().indexOf(params.id) + 1,
        },
        {
            headerName: t("supply.code"),
            field: "maNcc",
            key: "maNcc",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("supply.name"),
            field: "tenNcc",
            key: "tenNcc",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            //   headerName: t("hotel.numberOfRoom"),
            //   field: "tongSoPhong",
            //   key: "tongSoPhong",

            headerName: t("totalService"),
            field: "tongSoDichVu",
            key: "tongSoDichVu",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("email"),
            field: "email",
            key: "email",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("phone"),
            field: "sdt",
            key: "sdt",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("address"),
            field: "diaChi",
            key: "diaChi",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("status"),
            field: "trangThai",
            key: "trangThai",
            align: "center",
            flex: 1,
            headerAlign: "center",

            renderCell: ({ value, row }) => {
                return (
                    <Switch
                        defaultChecked={value}
                        onChange={() => handleBorderChange(row)}
                    />
                );
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            flex: 1,
            headerAlign: "center",

            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space size="large">
                        {/*<Typography.Link>*/}
                        {/*    <EyeOutlined onClick={() => viewRecord(record)}/>*/}
                        {/*</Typography.Link>*/}
                        <Typography.Link
                            disabled={editingKey !== ""}
                            onClick={() => edit(record.row)}
                        >
                            <EditOutlined style={{}} />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                headerName={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
        },
    ];

    const handleBorderChange = (record) => {
        let values = {
            id: record.id,
            hieu_luc: record.hieu_luc === true ? false : true,
            trangThai: record.trangThai,
        };
        apiEdit(values, "hieu_luc");
    };
    const apiEdit = (values, name_key) => {
        axios({
            method: "post",
            url: API_BASE_URL + "/nnc/thongtinchung/updateStatus",
            data: values,
            headers: authHeader(),
        }).then(function (response) {
            if (response.status === 200 && response.data.code === "00") {
                let updatedList = dataSource.map((item) => {
                    if (item.id === values.id) {
                        if (name_key === "trangThai") {
                            return { ...item, trangThai: values.trangThai };
                        } else {
                            return { ...item, hieu_luc: values.hieu_luc };
                        }
                    }
                    return item; // else return unmodified item
                });
                setDataSource(updatedList);
                api["success"]({
                    message: t("stour"),
                    description:
                        t("edit") + " " + t("noti.success").toLowerCase(),
                    duration: TIME_DURATION,
                });
            }
        });
    };
    const handleDelete = (record) => {
        const newData = dataSource.filter((item) => item.key !== record.key);
        let apiData = {
            id: record.id,
            trangThai: record.trangThai,
            hieuLuc: record.hieuLuc === true ? false : true,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "/nnc/thongtinchung/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:
                            t("delete") +
                            " " +
                            t("noti.error").toLowerCase() +
                            " " +
                            response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                })
            );
        setDataSource(newData);
    };
    const handleSearch = (e) => {
        console.log("=====", e);
    };
    const onSearch = (value) => {
        let data = [];
        for (let i in dataSearch) {
            if (
                dataSearch[i].value
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
            ) {
                let item = dataSearch[i].value.split(" - ");
                data.push({
                    stt: i,
                    id: item[0],
                    maNcc: item[1],
                    tenNcc: item[2],
                    email: item[3],
                    sdt: item[4],
                    diaChi: item[5],
                    trangThai: item[6],
                    tongSoDichVu: item[7],
                });
            }
        }
        setDataSource(data);
    };

    const QuickSearchToolbar = () => {
        return (
            <Box
                onClick={(e) => {
                    e.preventDefault();
                }}
            >
                <Row>
                    <Col xs={6} sm={6} md={6} lg={8} xl={6}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={4} sm={6} md={8} lg={4} xl={10}></Col>
                    <Col xs={6} sm={6} md={6} lg={12} xl={8}>
                        <FormActionButtonsFirst
                            keySide={keySide}
                            dichVu="boatTickets"
                        />
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            {contextHolder}
            <TableMui
                dataSource={dataSource}
                columns={columns}
                edit={edit}
                QuickSearchToolbar={QuickSearchToolbar}
            />
        </div>
    );
};
export default SpVeThuyen;
