import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { MAX_HEIGHT_SELECT } from "../../constants";

const SingleSelectMui = ({
    label,
    name,
    value,
    onChange,
    options = [],
    fullWidth = true,
    size = "small",
    required = false,
    errorText,
    sx = {},
    ...rest
}) => {
    return (
        <TextField
            name={name}
            sx={{
                marginBottom: 2,
                "& .MuiInputLabel-root": {
                    fontSize: "14px", // Font size của label
                },
                "& .MuiInputBase-input": {
                    fontSize: "13px", // Font size của input
                },
                ...sx, // Custom thêm nếu cần
            }}
            SelectProps={{
                MenuProps: {
                    PaperProps: {
                        sx: {
                            "& .MuiMenuItem-root": {
                                fontSize: "13px", // Font size của MenuItem
                            },
                        },
                    },
                    sx: { height: MAX_HEIGHT_SELECT },
                },
            }}
            size={size}
            label={label}
            select
            fullWidth={fullWidth}
            required={required}
            value={value ?? ""}
            onChange={onChange}
            error={!!errorText}
            helperText={errorText}
            {...rest}
            InputLabelProps={{ shrink: true }} // Truyền các props khác
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default SingleSelectMui;
