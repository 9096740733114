import React, { useState } from "react";
import { Row, Col } from "antd";

import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";

import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import DvNhaHang from "./DvNhaHang";
import FormActionButtonSecond from "../../../common/formActionButton/FormActionButtonSecond ";

const DvccNhaHang = ({
    dvName,
    dvId,
    isView,
    isDichVuKhac,
    QuickSearchToolbar: QuickSearchToolbarProp,
    reloadNew,
}) => {
    const [reload, setReload] = useState(reloadNew);

    const DefaultQuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={4} sm={6} md={8} lg={6} xl={10}></Col>
                    <Col xs={6} sm={6} md={6} lg={12} xl={8}>
                        <FormActionButtonSecond
                            dvId={dvId}
                            setReload={setReload}
                            dichVu="restaurant.dvcc"
                            isDichVuKhac={isDichVuKhac}
                        />
                    </Col>
                </Row>
            </Box>
        );
    };

    const QuickSearchToolbar =
        QuickSearchToolbarProp || DefaultQuickSearchToolbar;

    return (
        <DvNhaHang
            QuickSearchToolbar={QuickSearchToolbar}
            dvId={dvId}
            isDichVuKhac={isDichVuKhac}
            reloadNew={reload}
        />
    );
};

export default DvccNhaHang;
