/**
 * Đây là component tổ hợp chung cho 3 button thêm mới, import, export cho màn hình nhà cung cấp level 2
 *
 * @param {string} dvId - Đây là id của ncc
 * @param {string} dichVu - Đây là tên của dịch vụ
 * @param {void} setReload - Đây là hàm setReload
 * @param {void} dataAdd - Đây là hàm xử lý khi thêm mới
 * @param {boolean} isDichVuKhac - Đây là biến kiểm tra xem có phải tab dichVuKhac không
 * @param {string} dmDichVuId - Đây là id danh mục dịch vụ- chỉ truyền khi ở tab dịch vụ khác
 *
 *
 * @returns {JSX.Element}
 */

import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { Cascader, Col, Row, Space, notification } from "antd";
import { useTranslation } from "react-i18next";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { DownloadOutlined, ImportOutlined } from "@ant-design/icons";
import { useLocation, useSearchParams } from "react-router-dom";
import { API_BASE_URL, Ncc_ID, TIME_DURATION } from "../../../../constants";
import authHeader from "../../../../api/auth_header";
import ModalImportFile from "../modalImportFile/ModalImportFile";

// dynamic import component modal add new
const ModalHP = lazy(() => import("../../hotel/addhotel/tabHangPhong/ModalHP"));
const ModalPR = lazy(() => import("../../hotel/addhotel/tabGiaPhong/ModalPR"));
const ModalAddOto = lazy(() => import("../../oto/addOto/tabDichVuCC/ModalPR"));
const ModalAddRes = lazy(() =>
    import("../../restaurant/addRes/tabDichVuCC/ModalPR")
);
const ModalAddPriceCabin = lazy(() =>
    import("../../duThuyen/addDuThuyen/tabGiaPhong/ModalPR")
);
const ModalAddClassCabin = lazy(() =>
    import("../../duThuyen/addDuThuyen/tabLoaiPhong/ModalLP")
);
const ModalCommon = lazy(() => import("../../common/tabDichVuCC/ModalPR"));
const ModalAddLandTour = lazy(() =>
    import("../../landTour/addLandTour/tabDichVuCungCap/ModalPR")
);

const formActionButtonConfig = {
    "hotel.roomClass": {
        type: "KS_HANG_PHONG",
        urlAdd: "ncc/hangphong/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.hotel.roomClass",
    },
    "hotel.roomPrice": {
        type: "KS_GIA_PHONG",
        urlAdd: "ncc/giaphong/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.hotel.roomPrice",
    },
    "oto.dvcc": {
        type: "OTO_DV_CUNG_CAP",
        urlAdd: "ncc/dvOto/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.oto.dvcc",
    },
    "restaurant.dvcc": {
        type: "NH_DV_CUNG_CAP",
        urlAdd: "ncc/dvNhaHang/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.restaurant.dvcc",
    },
    "duthuyen.roomPrice": {
        type: "DT_GIA_CABIN",
        urlAdd: "ncc/giacabin/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.duthuyen.roomPrice",
    },
    "duthuyen.roomClass": {
        type: "DT_LOAI_CABIN",
        urlAdd: "ncc/loaicabin/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.duthuyen.roomClass",
    },
    "huong-dan-vien": {
        type: "HDV_DV_CUNG_CAP",
        urlAdd: "ncc/dvhdv/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.huong-dan-vien",
    },
    "may-bay": {
        type: "VMB_DV_CUNG_CAP",
        urlAdd: "ncc/dvMayBay/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.may-bay",
    },
    "ve-thuyen": {
        type: "VTH_DV_CUNG_CAP",
        urlAdd: "ncc/vethuyen/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.ve-thuyen",
    },
    visa: {
        type: "VISA_DV_CUNG_CAP",
        urlAdd: "ncc/dvVisa/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.visa",
    },
    "ve-thang-canh": {
        type: "VTC_DV_CUNG_CAP",
        urlAdd: "ncc/vethangcanh/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.ve-thang-canh",
    },
    "ve-bus": {
        type: "VB_DV_CUNG_CAP",
        urlAdd: "ncc/vexebus/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.ve-bus",
    },
    "ve-tau": {
        type: "VT_DV_CUNG_CAP",
        urlAdd: "ncc/vetau/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.ve-tau",
    },
    "dich-vu-khac": {
        type: "DVK_DV_CUNG_CAP",
        urlAdd: "ncc/dvkhac/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.dich-vu-khac",
    },
    "huong-dan-vien-tu-do": {
        type: "HDV_DV_CUNG_CAP",
        urlAdd: "ncc/dvhdv/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.huong-dan-vien-tu-do",
    },
    "landtour.dvcc": {
        type: "LT_DV_CUNG_CAP",
        urlAdd: "ncc/dvLandTour/saveData",
        urlExport: "ncc/files/export/excel",
        nameFile: "fileName.landtour.dvcc",
    },
};

const getformActionButtonConfig = (key) => {
    return formActionButtonConfig[key];
};

const FormActionButtonSecond = ({
    dvId,
    dataAdd,
    setReload,
    dichVu,
    isDichVuKhac,
    dmDichVuId,
}) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenUpload, setIsModalOpenUpload] = useState(false);
    const [dmDichVu, setDmDichVu] = useState([]);
    const [nameDichVuAddNew, setNameDichVuAddNew] = useState(dichVu);

    const location = useLocation();

    const [api, contextHolder] = notification.useNotification();

    const path = window.location.pathname.split("/")[2];

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const showModalUpload = () => {
        if (!dichVu) {
            api["error"]({
                message: t("stour"),
                description: t("not.otherServices"),
                duration: TIME_DURATION,
            });
            return;
        }
        setIsModalOpenUpload(true);
    };
    const handleCancelUpload = () => {
        setIsModalOpenUpload(false);
    };
    const handleFinishUpload = () => {
        setIsModalOpenUpload(false);
    };

    const handleFinish = (status, values) => {
        const { urlAdd } = getformActionButtonConfig(
            isDichVuKhac ? nameDichVuAddNew : dichVu
        );
        setIsModalOpen(status);
        // setIsModalOpen(false);
        let data = {
            nccThongTinChungId: searchParams.get("id"),
            ...values,
            isDichVuKhac: isDichVuKhac,
        };
        axios({
            method: "post",
            url: API_BASE_URL + urlAdd,
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataAdd(data);
                        }
                    }
                    setReload((prev) => !prev);
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("add") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:
                            t("add") +
                            " " +
                            response.data.message.toLowerCase(),
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });
    };

    const handleExportFile = () => {
        if (!dichVu) {
            api["error"]({
                message: t("stour"),
                description: t("not.otherServices"),
                duration: TIME_DURATION,
            });
            return;
        }

        const { type, urlExport, nameFile } = getformActionButtonConfig(
            isDichVuKhac ? nameDichVuAddNew : dichVu
        );
        const formData = new FormData();
        formData.append("type", type);
        formData.append("isDichVuKhac", isDichVuKhac);
        formData.append("nccTtcId", searchParams.get("id"));

        axios({
            method: "post",
            url: API_BASE_URL + urlExport,
            headers: {
                ...authHeader(),
                "Content-Type": "multipart/form-data",
            },
            data: formData,
        })
            .then((response) => {
                const { data } = response.data;
                if (!data) {
                    console.error("No data found in response!");
                    api["error"]({
                        message: t("stour"),
                        description: t("not.download"),
                        duration: TIME_DURATION,
                    });
                    return;
                }

                const binaryData = atob(data);
                const arrayBuffer = new Uint8Array(binaryData.length);
                for (let i = 0; i < binaryData.length; i++) {
                    arrayBuffer[i] = binaryData.charCodeAt(i);
                }

                const blob = new Blob([arrayBuffer], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                const getNameFile = t("fileName.info") + " " + t(nameFile);
                link.setAttribute(
                    "download",
                    `${getNameFile.toUpperCase()}.xlsx`
                );
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error("Download failed: ", error);
            });
    };

    //this is props of modal import file
    const title =
        t("addNewInfo") +
        " " +
        t(dichVu).toLocaleLowerCase() +
        " " +
        t("fromexcel");
    const noteList = [
        t(`note.${dichVu}.1`) !== `note.${dichVu}.1`
            ? t(`note.${dichVu}.1`)
            : t("note.add.1"),
        t("note.add.2"),
        t("note.add.3"),
        t("note.add.4"),
        t("note.add.5") + " " + t(dichVu).toLocaleLowerCase(),
    ];

    const renderModalAddNew = useCallback(() => {
        try {
            switch (isDichVuKhac ? nameDichVuAddNew : dichVu) {
                case "hotel.roomClass":
                    return (
                        <ModalHP
                            isOpen={isModalOpen}
                            handleCancel={handleCancel}
                            handleFinish={handleFinish}
                        />
                    );
                case "hotel.roomPrice":
                    return (
                        <ModalPR
                            isOpen={isModalOpen}
                            handleCancel={handleCancel}
                            handleFinish={handleFinish}
                            dvId={dvId}
                        />
                    );
                case "oto.dvcc":
                    return (
                        <ModalAddOto
                            isOpen={isModalOpen}
                            handleCancel={handleCancel}
                            handleFinish={handleFinish}
                            dvId={dvId}
                        />
                    );
                case "restaurant.dvcc":
                    return (
                        <ModalAddRes
                            isOpen={isModalOpen}
                            handleCancel={handleCancel}
                            handleFinish={handleFinish}
                            dvId={dvId}
                        />
                    );
                case "duthuyen.roomPrice":
                    return (
                        <ModalAddPriceCabin
                            isOpen={isModalOpen}
                            handleCancel={handleCancel}
                            handleFinish={handleFinish}
                            dvId={dvId}
                        />
                    );
                case "duthuyen.roomClass":
                    return (
                        <ModalAddClassCabin
                            isOpen={isModalOpen}
                            handleCancel={handleCancel}
                            handleFinish={handleFinish}
                            dvId={dvId}
                        />
                    );

                case "landtour.dvcc":
                    return (
                        <ModalAddLandTour
                            isOpen={isModalOpen}
                            handleCancel={handleCancel}
                            handleFinish={handleFinish}
                            dvId={dvId}
                            title={t("add")}
                        />
                    );

                default:
                    return (
                        <ModalCommon
                            isOpen={isModalOpen}
                            handleCancel={handleCancel}
                            handleFinish={handleFinish}
                            dvId={dvId}
                            dvName={isDichVuKhac ? nameDichVuAddNew : dichVu}
                        />
                    );
            }
        } catch (error) {
            console.error("Error rendering modal:", error);
            return null;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dichVu, isModalOpen, dvId]);

    const handleOnChange = (value) => {
        let serviceSlug = getServiceSlug(value[1] ?? value[0]);
        setNameDichVuAddNew(serviceSlug);
        showModal(true);
    };

    const getServiceSlug = useCallback((dichVu) => {
        const serviceMap = {
            1.1: "hotel.roomClass",
            1.2: "hotel.roomPrice",
            2: "oto.dvcc",
            3: "restaurant.dvcc",
            4.1: "duthuyen.roomClass",
            4.2: "duthuyen.roomPrice",
            5: "huong-dan-vien",
            6: "may-bay",
            7: "ve-thuyen",
            8: "visa",
            9: "ve-thang-canh",
            10: "ve-bus",
            11: "ve-tau",
            12: "dich-vu-khac",
            13: "landtour.dvcc",
            14: "huong-dan-vien-tu-do",
        };

        return serviceMap[dichVu] || null;
    }, []);

    useEffect(() => {
        if (isDichVuKhac) {
            setNameDichVuAddNew(dichVu);
        } else {
            setNameDichVuAddNew(dichVu);
        }
    }, [dichVu, isDichVuKhac]);

    // lấy danh mục dịch vụ
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let dataDV = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (
                                data[i].hieu_luc === true &&
                                data[i].id !== Ncc_ID[path] &&
                                data[i].id !== 12 &&
                                data[i].id !== 14
                            ) {
                                if (data[i].id === 1) {
                                    dataDV.push({
                                        value: data[i].id,
                                        label: data[i].ten_dich_vu,
                                        children: [
                                            {
                                                value: 1.1,
                                                label: "Hạng Phòng",
                                            },
                                            {
                                                value: 1.2,
                                                label: "Giá Phòng",
                                            },
                                        ],
                                    });
                                } else if (data[i].id === 4) {
                                    dataDV.push({
                                        value: data[i].id,
                                        label: data[i].ten_dich_vu,
                                        children: [
                                            {
                                                value: 4.1,
                                                label: "Hạng cabin",
                                            },
                                            {
                                                value: 4.2,
                                                label: "Giá cabin",
                                            },
                                        ],
                                    });
                                } else {
                                    dataDV.push({
                                        value: data[i].id,
                                        label: data[i].ten_dich_vu,
                                    });
                                }
                            }
                        }
                        setDmDichVu(dataDV);
                    }
                }
            })
            .catch(function (response) {
                console.error("Error response: ====" + response);
            });
    }, [location.pathname, path]);

    return (
        <Row>
            {contextHolder}
            <Col xs={12} sm={12} md={12} lg={12} xl={20}>
                <Space size="small">
                    <Button
                        variant="outlined"
                        sx={{ whiteSpace: "nowrap" }}
                        startIcon={<DownloadOutlined />}
                        onClick={handleExportFile}
                    >
                        {t("exportFile")}
                    </Button>
                    <Button
                        sx={{ whiteSpace: "nowrap" }}
                        variant="outlined"
                        startIcon={<ImportOutlined />}
                        onClick={showModalUpload}
                        type="dashed"
                    >
                        {t("importFile")}
                    </Button>

                    {isDichVuKhac ? (
                        <Cascader
                            defaultValue={dichVu === 1.1 ? [1, 1.1] : 2}
                            options={dmDichVu}
                            expandTrigger="hover"
                            onChange={(value) => handleOnChange(value)}
                            allowClear={false}
                            displayRender={() => {
                                return (
                                    <div
                                        style={{
                                            textTransform: "uppercase",
                                            fontWeight: "500",
                                            display: "flex",
                                            gap: "5px",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            color: "#ed6c02",
                                        }}
                                    >
                                        <AddIcon />
                                        {t("add")}
                                    </div>
                                );
                            }}
                            status="warning"
                            style={{
                                width: "fit-content",
                                height: "36.1px",
                            }}
                        />
                    ) : (
                        <Button
                            sx={{ whiteSpace: "nowrap" }}
                            variant="outlined"
                            onClick={showModal}
                            startIcon={<AddIcon />}
                            color="warning"
                        >
                            {t("add")}
                        </Button>
                    )}

                    {dichVu && (
                        <ModalImportFile
                            isOpen={isModalOpenUpload}
                            handleCancel={handleCancelUpload}
                            handleFinish={handleFinishUpload}
                            keySide={dmDichVuId || searchParams.get("key")}
                            title={title}
                            noteList={noteList}
                            name={dichVu}
                            nccTtcId={searchParams.get("id")}
                            isDichVuKhac={isDichVuKhac}
                        />
                    )}

                    <Suspense fallback={<div>Loading...</div>}>
                        {renderModalAddNew()}
                    </Suspense>
                </Space>
            </Col>
        </Row>
    );
};
export default FormActionButtonSecond;
