import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const getCauHinhVeThangCanhTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/layCauHinhVeThangCanhTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const updateCauHinhVeThangCanhTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/updateCauHinhVeThangCanhTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const saveCauHinhVeThangCanh = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/saveCauHinhVeThangCanh", data, {
    headers: authHeader(),
  });
};

const getNccVeThangCanh = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getNccVeThangCanh", data, {
    headers: authHeader(),
  });
};

const xoaCauHinhVeThangCanh = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/xoaCauHinhVeThangCanh", data, {
    headers: authHeader(),
  });
};

export default {
  getCauHinhVeThangCanhTheoMap,
  updateCauHinhVeThangCanhTheoMap,
  saveCauHinhVeThangCanh,
  getNccVeThangCanh,
  xoaCauHinhVeThangCanh,
};
