import React, { useState } from "react";
import { Row, Col } from "antd";

import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";

import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import PriceRoom_materal from "./PriceRoom_materal";
import FormActionButtonSecond from "../../../common/formActionButton/FormActionButtonSecond ";

const PriceRoomDuThuyen = ({ dvName, dvId, isView }) => {
    const [reload, setReload] = useState(false);
    const QuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={4} sm={6} md={8} lg={6} xl={10}></Col>
                    <Col xs={6} sm={6} md={6} lg={12} xl={8}>
                        <FormActionButtonSecond
                            dvId={dvId}
                            setReload={setReload}
                            dichVu="duthuyen.roomPrice"
                            isDichVuKhac={false}
                        />
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <PriceRoom_materal
            QuickSearchToolbar={QuickSearchToolbar}
            dvId={dvId}
            isDichVuKhac={false}
            reloadNew={reload}
        />
    );
};

export default PriceRoomDuThuyen;
