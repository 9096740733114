import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const getCauHinhVisaTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/layCauHinhVisaTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const updateCauHinhVisaTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/updateCauHinhVisaTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const saveCauHinhVisa = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/saveCauHinhVisa", data, {
    headers: authHeader(),
  });
};

const getNccVisa = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getNccVisa", data, {
    headers: authHeader(),
  });
};

const xoaCauHinhVisa = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/xoaCauHinhVisa", data, {
    headers: authHeader(),
  });
};

export default {
  getCauHinhVisaTheoMap,
  updateCauHinhVisaTheoMap,
  saveCauHinhVisa,
  getNccVisa,
  xoaCauHinhVisa,
};
