import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const getCauHinhVeBusTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/layCauHinhBusTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const updateCauHinhVeBusTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/updateCauHinhBusTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const saveCauHinhVeBus = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/saveCauHinhBus", data, {
    headers: authHeader(),
  });
};

const getNccVeBus = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getNccVeBus", data, {
    headers: authHeader(),
  });
};

const xoaCauHinhVeBus = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/xoaCauHinhBus", data, {
    headers: authHeader(),
  });
};

export default {
  getCauHinhVeBusTheoMap,
  updateCauHinhVeBusTheoMap,
  saveCauHinhVeBus,
  getNccVeBus,
  xoaCauHinhVeBus,
};
