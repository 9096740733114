import React, { useEffect, useState } from "react";
import { Tabs } from "antd";

import { useTranslation } from "react-i18next";
import GeneralInfo from "./tabThongTinChung/GeneralInfo";
import Contact from "./tabLienHe/Contact";
import DvccNhaHang from "./tabDichVuCC/DvccNhaHang";
import AccountBanking from "./tabTKNH/AccountBanking";
import { useLocation } from "react-router-dom";
import DichVuKhac from "../../common/tabDichVuKhac/DichVuKhac";

const AddRestaurant = () => {
    const onChange = (key) => {
        console.log(key);
    };
    const location = useLocation();
    const [isView, setIsView] = useState(location.pathname.includes("view"));
    const [isNew, setIsNew] = useState();
    const [isDis, setIsDis] = useState(
        location.pathname.includes("new") ? true : false
    );
    const [idNCC, setIdNCC] = useState(0);
    useEffect(() => {
        getDvId();
    }, []);
    const getDvId = (value) => {
        let Id = 0;

        if (value === undefined || value === null) {
            const params = new URLSearchParams(location.search);
            Id = params.get("id") || 0;
        } else {
            Id = value;
        }

        setIdNCC(Id);
    };
    const saveNew = (value) => {
        setIdNCC(value);
    };
    const getDv = () => {
        let name = "";
        if (location.pathname.length > 0) {
            let id = location.pathname.split("/");
            name = id[id.length - 2];
        }
        return name;
    };
    const { t } = useTranslation();
    const addId = (status) => {
        if (isNew) {
            if (status !== undefined) {
                if (status) {
                    return setIsDis(false);
                } else {
                    return setIsDis(true);
                }
            } else {
                return setIsDis(false);
            }
        } else {
            return setIsDis(false);
        }
    };
    const items = [
        {
            key: "1",
            label: t("hotel.generalInfo"),
            children: (
                <GeneralInfo
                    dvName={getDv()}
                    dvId={idNCC}
                    addId={addId}
                    isView={isView}
                    newId={saveNew}
                />
            ),
        },
        {
            key: "2",
            label: t("contact"),
            children: <Contact dvName={getDv()} dvId={idNCC} isView={isView} />,
            disabled: isDis,
        },
        {
            key: "3",
            label: t("res.serviceProvided"),
            children: (
                <DvccNhaHang dvName={getDv()} dvId={idNCC} isView={isView} />
            ),
            disabled: isDis,
        },
        {
            key: "4",
            label: t("dichVuKhac"),
            children: <DichVuKhac />,
            disabled: isDis,
        },
        {
            key: "5",
            label: t("hotel.accountBanking"),
            children: (
                <AccountBanking dvName={getDv()} dvId={idNCC} isView={isView} />
            ),
            disabled: isDis,
        },
    ];
    return (
        <Tabs
            defaultActiveKey="1"
            items={items}
            onChange={onChange}
            style={{ marginLeft: "10px" }}
        />
    );
};
export default AddRestaurant;
