import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";
import axios from "axios";
import { API_BASE_URL, Ncc_ID } from "../../constants";
import authHeader from "../../api/auth_header";
import SpHotel from "./hotel/SpHotel";
import SpRestaurant from "./restaurant/SpRestaurant";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import SpOto from "./oto/SpOto";
import SpVisa from "./visa/SpVisa";
import SpDuThuyen from "./duThuyen/SpDuThuyen";
import SpPlane from "./mayBay/SpPlane";
import SpThangCanh from "./thangCanh/SpThangCanh";
import SpVeThuyen from "./veThuyen/SpVeThuyen";
import SpVeBus from "./veBus/SpVeBus";
import SpVeTau from "./veTau/SpVeTau";
import SpDvKhac from "./dvKhac/SpDvKhac";
import SpHdvTuDo from "./hdVienTudo/SpHdvTuDo";
import SpHdvCty from "./hdVienCty/SpHdvCty";
import SpLandTour from "./landTour/SpLandTour";
import SingleSelectMui from "../layout/SingleSelectMui";
import InputMui from "../layout/InputMui";

const Ncc = () => {
    const [dataDv, setDataDv] = useState([]);
    const [idNcc, setidNcc] = useState(1);
    const [nameKey, setNameKey] = useState("Khách sạn");
    const location = useLocation();
    const { t } = useTranslation();
    const getDv = () => {
        let name = "";
        if (location.pathname.length > 0) {
            let id = location.pathname.split("/");
            name = id[id.length - 1];
        }
        return name;
    };
    useEffect(() => {
        if (dataDv.length > 0) {
            let idNccLink = Ncc_ID[getDv()];
            setidNcc(idNccLink ? idNccLink : 1);
        }
    }, [dataDv, location]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmDichVu",
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let dataDv = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].hieu_luc === true) {
                                dataDv.push({
                                    value: data[i].id,
                                    label: data[i].ten_dich_vu,
                                    hieu_luc: data[i].hieu_luc,
                                });
                            }
                        }
                        setDataDv(dataDv);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    // // const html = () => {
    // //     if (dataDv.length > 0) {
    // //         for (let i in dataDv) {
    // //             switch (dataDv[i].id) {
    // //                 case 1:
    // //                     return <SpHotel/>
    // //                 case 2:
    // //                     return <SpOto/>
    // //                 case 3:
    // //                     return <SpRestaurant/>

    // //                 default:
    // //                     return <SpHotel/>;
    // //             }
    // //         }
    // //     }
    // // }
    const handleChange = (e) => {
        setidNcc(e.target.value);
        setNameKey(dataDv[parseInt(e.target.value) - 1].label);
    };
    const dataRender = () => {
        let idNcc_link = Ncc_ID[getDv()];
        let pathName = location.pathname.split("/")[2];
        if (
            idNcc !== 500 ||
            pathName !== undefined ||
            idNcc_link !== undefined
        ) {
            let Id = idNcc !== 500 ? idNcc : idNcc_link;
            switch (Id) {
                case 1:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpHotel keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 2:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpOto keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 3:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpRestaurant keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 4:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpDuThuyen keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 5:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpHdvCty keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 6:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpPlane keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 7:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpVeThuyen keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 8:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpVisa keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 9:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpThangCanh keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 10:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpVeBus keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 11:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpVeTau keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 12:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpDvKhac keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 13:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpLandTour keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                case 14:
                    return (
                        <Link to={Id} style={{ width: "100%" }}>
                            <SpHdvTuDo keySide={Id} nameKey={nameKey} />
                        </Link>
                    );
                default:
                    return null;
            }
        }
    };

    const renderSelect = () => {
        // // let idNccLink = Ncc_ID[getDv()];
        // // return (
        //     // <Select
        //     //     style={{ width: "100%" }}
        //     //     showSearch
        //     //     optionFilterProp="children"
        //     //     filterOption={(input, option) => (option?.label ?? "").includes(input)}
        //     //     filterSort={(optionA, optionB) =>
        //     //         (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
        //     //     }
        //     //     defaultValue={1}
        //     //     options={dataDv}
        //     //     onChange={handleChange}
        //     // />

        // );
        return (
            <SingleSelectMui
                select
                fullWidth
                key={Math.random()}
                value={idNcc}
                onChange={handleChange}
                options={dataDv}
            ></SingleSelectMui>
        );
    };
    return (
        <div style={{ marginTop: "2px" }}>
            <Row
                style={{
                    borderStyle: "outset",
                    borderRadius: "6px",
                    backdropColor: "ghostwhite",
                    height: "50px",
                }}
            >
                <Col
                    xs={8}
                    sm={8}
                    md={7}
                    lg={6}
                    xl={4}
                    style={{ marginTop: "5px", marginLeft: "5px" }}
                >
                    <InputMui
                        value={t("supplier")}
                        inputProps={{
                            style: { fontWeight: "bold" },
                        }}
                    />
                </Col>

                <Col
                    xs={8}
                    sm={8}
                    md={7}
                    lg={6}
                    xl={4}
                    style={{ marginTop: "5px" }}
                >
                    {renderSelect()}
                </Col>
            </Row>
            <Row style={{ marginTop: "20px" }}>{dataRender()}</Row>
        </div>
    );
};
export default Ncc;
