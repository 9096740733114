import React, { useEffect, useState } from "react";
import {
    Col,
    Row,
    Space,
    Input,
    Dropdown,
    Form,
    Select,
    theme,
    Affix,
} from "antd";
import { BarsOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import { API_BASE_URL } from "../../../constants";
import authHeader from "../../../api/auth_header";
import axios from "axios";
import FormActionButtonsFirst from "../common/formActionButton/FormActionButtonsFirst";

const { Search } = Input;
const { useToken } = theme;
const FormHdvTd = ({ dataAdd, keySide, handleSearch }) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [dataNgonNgu, setDataNgonNgu] = useState([]);
    const [bottom, setBottom] = React.useState(10);
    const onClickAdd = () => {
        switch (keySide) {
            case 14:
                return navigate("/ncc/huong-dan-vien-tu-do/new");
            default:
                return null;
        }
    };
    const items = [
        {
            key: "1",
            label: <h3>{t("search")}</h3>,
        },
        {
            key: "2",
            label: <h3>{t("search")}</h3>,
        },
    ];
    const loaiThes = [
        { value: "Thẻ quốc tế", label: t("internationalCard") },
        { value: "Thẻ nội địa", label: t("localCard") },
        { value: "Thẻ địa điểm", label: t("locationCard") },
    ];
    const trangThais = [
        { value: true, label: "Active" },
        { value: false, label: "Closed" },
    ];
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmNgoaiNgu",
            headers: authHeader(),
        })
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data.data
                        .filter((item) => item.hieu_luc)
                        .map((item) => ({
                            value: item.id,
                            label: item.ngoai_ngu,
                        }));
                    setDataNgonNgu(data);
                }
            })
            .catch((error) => {
                console.error("Error fetching languages:", error);
            });
    }, []);
    const handleClear = () => {
        form.resetFields();
    };
    const onSearch = (params) => {
        handleSearch(params);
    };
    const { token } = useToken();
    const onFinish = (values) => {
        console.log("Form values:", values);
        const params = { ...values, pageIndex: 1, pageSize: 100 };
        handleSearch(params);
    };
    const contentStyle = {
        width: "300px",
        overflowY: "scroll",
        height: "400px",
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        border: "4px solid #CFC8C8",
    };
    const menuStyle = {
        boxShadow: "none",
    };
    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                <Search
                    allowClear
                    placeholder={t("fullname") + "/ " + t("phone")}
                    onSearch={onSearch}
                />
            </Col>
            <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                <Dropdown
                    menu={{
                        items,
                    }}
                    dropdownRender={(menu) => (
                        <div style={contentStyle}>
                            <Form
                                form={form}
                                style={{ marginLeft: "10px" }}
                                onFinish={onFinish}
                                layout="vertical"
                            >
                                <h4>
                                    {t("search") +
                                        ", " +
                                        t("lookup").toLowerCase()}
                                </h4>

                                <Form.Item
                                    name="hoVaTen"
                                    label={t("fullname")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="loaiThe"
                                    label={t("cardType")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Select>
                                        {loaiThes.map((option) => (
                                            <Select.Option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="ngoaiNgu"
                                    label={t("languages")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Select mode="multiple">
                                        {dataNgonNgu.map((option) => (
                                            <Select.Option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="trangThai"
                                    label={t("status")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Select>
                                        {trangThais.map((option) => (
                                            <Select.Option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name="sdt"
                                    label={t("phone")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    label={t("email")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name="diaChi"
                                    label={t("location.city")}
                                    rules={[
                                        {
                                            required: false,
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Affix offsetBottom={50} offsetTop={50}>
                                    <Space>
                                        <Button
                                            type="primary"
                                            onClick={() => form.submit()}
                                        >
                                            {t("action.apply")}
                                        </Button>
                                        <Button
                                            style={{
                                                backgroundColor: "#898989",
                                                color: "white",
                                            }}
                                            key="clear"
                                            onClick={handleClear}
                                        >
                                            {t("action.reset")}
                                        </Button>
                                    </Space>
                                </Affix>
                            </Form>
                        </div>
                    )}
                >
                    <Button icon={<BarsOutlined />} type="dashed">
                        {t("filter")}
                    </Button>
                </Dropdown>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                <FormActionButtonsFirst
                    keySide={keySide}
                    dichVu="freelanceTourGuide" // yacht: du thuyền
                />
            </Col>
        </Row>
    );
};
export default FormHdvTd;
