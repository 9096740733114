import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const getCauHinhHdvTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/layCauHinhHdvTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const updateCauHinhHdvTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/updateCauHinhHdvTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const saveCauHinhHdv = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/saveCauHinhHdv", data, {
    headers: authHeader(),
  });
};

const getQuocGia = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachQuocGia", data, {
    headers: authHeader(),
  });
};

const getTinhTP = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachTinhThanhPho", data, {
    headers: authHeader(),
  });
};

// const getDanhMucBuaAn = (data) => {
//   return axios.post(API_BASE_URL + "category/getDmBuaAn", data, {
//     headers: authHeader(),
//   });
// };

const getNccHdv = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getNccHdv", data, {
    headers: authHeader(),
  });
};

const xoaCauHinhHdv = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/xoaCauHinhHdv", data, {
    headers: authHeader(),
  });
};

export default {
  getCauHinhHdvTheoMap,
  updateCauHinhHdvTheoMap,
  saveCauHinhHdv,
  getQuocGia,
  getTinhTP,
  getNccHdv,
  xoaCauHinhHdv,
};
