import { MinusCircleOutlined } from "@ant-design/icons";
import { Button } from "@mui/material";
import {Affix, Card, Col, DatePicker, Divider, Form, Modal, Row, Space, Switch} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import authHeader from "../../../../../api/auth_header";
import "../../../../../assets/css/supplier/holtel/priceRoom/modalPr.css";
import { API_BASE_URL, data_getDM } from "../../../../../constants";
import TableModalGP from "./TableModalGP";
import Save from "@mui/icons-material/Save";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Delete from "@mui/icons-material/Delete";
import SingleSelectMui from "../../../../layout/SingleSelectMui";
import InputMui from "../../../../layout/InputMui";


const { RangePicker } = DatePicker;

const ModalPrAction = ({ isOpen, handleCancel, handleFinish, record, action, dvId }) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [hangPhong, setHangPhong] = useState([]);
    const [tiGia, setTiGia] = useState([]);
    const [dataSourceTable, setDataSourceTable] = useState([]);
    const [idTable, setIdTable] = useState([]);
    const [hangPhongId, setHangPhongId] = useState();
    const [tienId, setTienId] = useState();
    const [dataSource, setDataSource] = useState();

    const [id, setId] = useState(0);
    const [nccThongTinChungId, setNccThongTinChungId] = useState(0);
    const [maDichVu, setMaDichVu] = useState();
    const [nccLoaiCabinId, setNccLoaiCabinId] = useState(0);
    const [tenDichVu, setTenDichVu] = useState("");
    const [dmTyGiaId, setDmTyGiaId] = useState(0);
    const [ghiChu, setGhiChu] = useState("");
    const [loaiCabin, setLoaiCabin] = useState();

    const [errorId, setErrorId] = useState(false);
    const [errornccThongTinChungId, setErrornccThongTinChungId] = useState(false);
    const [errorNccLoaiCabinId, setErrorNccLoaiCabinId] = useState(false);
    const [errorTenDichVu, setErrorTenDichVu] = useState(false);
    useEffect(() => {
        let apiData = {
            id: record.isDouble ? record.ids : record.id,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/giacabin/getDataById",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dataArr = [];
                    setHangPhongId(data.nccLoaiCabinId);
                    setTienId(data.maNgoaiTe);
                    var items = [];
                    if (data.tranxTimeApDungRequests.length > 0) {
                        let dataFit = data.tranxTimeApDungRequests;
                        var dataSource = [];
                        for (let k = 0; k < dataFit.length; k++) {
                            var tuNgay = dataFit[k].tuNgay.split("/");
                            var denNgay = dataFit[k].denNgay.split("/");
                            items.push({
                                id: dataFit[k].id,
                                nccGiaCabinId: dataFit[k].nccGiaCabinId,
                                rangeTime: [
                                    dayjs(new Date(tuNgay[2], tuNgay[1] - 1, tuNgay[0])),
                                    dayjs(new Date(denNgay[2], denNgay[1] - 1, denNgay[0])),
                                ],
                                trangThaiBooking: dataFit[k].trangThaiBooking,
                            });
                            dataArr.push({
                                id: dataFit[k].id,
                                nccGiaCabinId: dataFit[k].nccGiaCabinId,
                                rangeTime: [
                                    dayjs(new Date(tuNgay[2], tuNgay[1] - 1, tuNgay[0])),
                                    dayjs(new Date(denNgay[2], denNgay[1] - 1, denNgay[0])),
                                ],
                                trangThaiBooking: dataFit[k].trangThaiBooking,
                                children: [
                                    {
                                        id: 0,
                                        rowKey: Math.random() * (100 - 1),
                                        typePrice: "Fit",
                                        fitNgayThuongNet: dataFit[k].fitNgayThuongNet,
                                        fitNgayThuongGiaBan: dataFit[k].fitNgayThuongGiaBan,
                                        fitCuoiTuanNet: dataFit[k].fitCuoiTuanNet,
                                        fitCuoiTuanGiaBan: dataFit[k].fitCuoiTuanGiaBan,
                                    },
                                    {
                                        id: 1,
                                        rowKey: Math.random() * (100 - 1),
                                        typePrice: "Extra",
                                        fitNgayThuongNet: dataFit[k].extraNgayThuongNet,
                                        fitNgayThuongGiaBan: dataFit[k].extraNgayThuongGiaBan,
                                        fitCuoiTuanNet: dataFit[k].extraCuoiTuanNet,
                                        fitCuoiTuanGiaBan: dataFit[k].extraCuoiTuanGiaBan,
                                    },
                                ],
                            });
                            dataSource.push({
                                fit: {
                                    fitNgayThuongNet: dataFit[k].fitNgayThuongNet,
                                    fitNgayThuongGiaBan: dataFit[k].fitNgayThuongGiaBan,
                                    fitCuoiTuanNet: dataFit[k].fitCuoiTuanNet,
                                    fitCuoiTuanGiaBan: dataFit[k].fitCuoiTuanGiaBan,
                                },
                                extra: {
                                    fitNgayThuongNet: dataFit[k].extraNgayThuongNet,
                                    fitNgayThuongGiaBan: dataFit[k].extraNgayThuongGiaBan,
                                    fitCuoiTuanNet: dataFit[k].extraCuoiTuanNet,
                                    fitCuoiTuanGiaBan: dataFit[k].extraCuoiTuanGiaBan,
                                },
                                key: k,
                            });
                        }
                    }
                    setId(data.id);
                    setNccThongTinChungId(data.nccThongTinChungId);
                    setNccLoaiCabinId(data.nccLoaiCabinId);
                    setMaDichVu(data.maDichVu);
                    setDmTyGiaId(data.dmTyGiaId);
                    setTenDichVu(data.tenDichVu);
                    setGhiChu(data.ghiChu);
                    setLoaiCabin(data.loaiCabin);
                    form.setFieldsValue({
                        items: items,
                    });
                    setDataSource(dataSource);
                    setDataSourceTable(dataArr);
                    setIdTable(data.id);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, [form, record.id]);
    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    useEffect(() => {
        let data = {
            nccThongTinChungId: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/loaicabin/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200) {
                    console.log("Response hangphong: ====" + response.data.data);
                    let dataHP = [];
                    let data = response.data.data;
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataHP.push({
                                value: data[i].id,
                                label: data[i].loaiCabin,
                            });
                        }
                        setHangPhong(dataHP);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
            });
    }, []);
    const onFinish = (values) => {
        setErrorId(false);
        setErrorNccLoaiCabinId(false);
        setErrorTenDichVu(false);
        var error = false;
        if (!maDichVu) {
            setErrorId(true);
            error = true;
        }
        if (!nccLoaiCabinId) {
            setErrorNccLoaiCabinId(true);
            error = true;
        }
        if (!tenDichVu) {
            setErrorTenDichVu(true);
            error = true;
        }
        if (error) return;
        form.resetFields();
        setOpen(false);
        var tranxTimeApDungRequests = [];
        if (values.items) {
            for (let i = 0; i < values.items.length; i++) {
                const element = values.items[i];
                var index = dataSource.findIndex((item) => item.key === i);
                tranxTimeApDungRequests.push({
                    nccGiaCabinId: element.nccGiaCabinId,
                    id: element.id,
                    tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                    denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                    fitNgayThuongNet: dataSource[0].fit.fitNgayThuongNet,
                    fitNgayThuongGiaBan: dataSource[0].fit.fitNgayThuongGiaBan,
                    fitCuoiTuanNet: dataSource[0].fit.fitCuoiTuanNet,
                    fitCuoiTuanGiaBan: dataSource[0].fit.fitCuoiTuanGiaBan,
                    extraNgayThuongNet:
                        index === 1 ? dataSource[0].extra.fitNgayThuongNet : dataSource[1].extra.fitNgayThuongNet,
                    extraNgayThuongGiaBan:
                        index === 1
                            ? dataSource[0].extra.fitNgayThuongGiaBan
                            : dataSource[1].extra.fitNgayThuongGiaBan,
                    extraCuoiTuanNet:
                        index === 1 ? dataSource[0].extra.fitCuoiTuanNet : dataSource[1].extra.fitCuoiTuanNet,
                    extraCuoiTuanGiaBan:
                        index === 1 ? dataSource[0].extra.fitCuoiTuanGiaBan : dataSource[1].extra.fitCuoiTuanGiaBan,
                    trangThaiBooking: element.trangThaiBooking ? element.trangThaiBooking : false,
                });
            }
        }
        let data = {
            id: id,
            nccThongTinChungId: dvId,
            nccLoaiCabinId: nccLoaiCabinId,
            loaiCabin: hangPhong.label,
            tenDichVu: tenDichVu,
            dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
            maDichVu: maDichVu,
            loaiTien: tiGia[dmTyGiaId] ? tiGia[dmTyGiaId].label : "",
            ghiChu: ghiChu ? ghiChu : "",
            tranxTimeApDungRequests: tranxTimeApDungRequests,
        };
        console.log(JSON.stringify(data));
        clearData();
        handleFinish(open, data);
    };
    const handleCancels = () => {
        form.resetFields();
        clearData();
        handleCancel();
    };
    const clearData = () => {
        setDmTyGiaId(0);
        setMaDichVu("");
        setNccLoaiCabinId("");
        setTenDichVu("");
        setGhiChu("");
    };
    const dataRender = () => {
        let html;
        html = (
            <Space direction="vertical" size="small" style={{ display: "flex" }}>
                <Form.Item name="id" hidden></Form.Item>
                <Form.Item name="nccThongTinChungId" hidden></Form.Item>
                <div className="">
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.List name="items">
                                {(fields, { add, remove }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            rowGap: 16,
                                            flexDirection: "column",
                                        }}
                                    >
                                        {fields.map(({ key, name, ...restField }) => (
                                            <Card size="large" key={key}>
                                                <Row>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "id"]}
                                                        hidden={true}
                                                    ></Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "nccGiaCabinId"]}
                                                        hidden={true}
                                                    ></Form.Item>
                                                    <Col xs={14} sm={14} md={14} lg={14} xl={14}>
                                                        <Form.Item
                                                            label={t("hotel.priceRoom.applicationTime")}
                                                            {...formItemLayoutDate}
                                                            {...restField}
                                                            name={[name, "rangeTime"]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <RangePicker
                                                                style={{ width: "100%" }}
                                                                placeholder={[t("toDate"), t("fromDate")]}
                                                                disabledDate={(current) => {
                                                                    let customDate = moment().format("DD/MM/YYYY");
                                                                    return (
                                                                        current &&
                                                                        current < moment(customDate, "DD/MM/YYYY")
                                                                    );
                                                                }}
                                                                format="DD/MM/YYYY"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={5} sm={5} md={5} lg={5} xl={5}></Col>
                                                    <Col xs={2} sm={2} md={2} lg={2} xl={2}>
                                                        <MinusCircleOutlined
                                                            className="dynamic-delete-button"
                                                            onClick={() => remove(name)}
                                                        />
                                                    </Col>

                                                </Row>

                                                <Row>
                                                    <TableModalGP
                                                        style={{ width: "100%" }}
                                                        keyNum={key}
                                                        setDataEdit={setDataSource}
                                                        dataEdit={dataSource}
                                                        dataS={
                                                            dataSourceTable[key]?.children
                                                                ? dataSourceTable[key].children
                                                                : null
                                                        }
                                                    />
                                                </Row>
                                                <Row>
                                                    <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                        <Form.Item
                                                            {...restField}
                                                            valuePropName="checked"
                                                            name={[name, "trangThaiBooking"]}
                                                            label={t("status") + " " + "booking"}
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message:
                                                                        t("validate") +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <Switch />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        ))}
                                        <Row>
                                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                                <Button type="primary" onClick={() => add()} block>
                                                    {t("add") + " " + t("time").toLowerCase()}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                )}
                            </Form.List>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <InputMui
                                sx={{
                                    marginBottom: 2,
                                    marginTop: 2,
                                }}
                                value={ghiChu}
                                fullWidth
                                onChange={(e) => setGhiChu(e.target.value)}
                                multiline
                                rows={5}
                                label={t("notes")}
                            ></InputMui>
                        </Col>
                    </Row>
                </div>
            </Space>
        );
        return html;
    };

    const dataRenderMui = () => {
        return (
            <>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={10}>
                        <InputMui
                            onChange={(e) =>
                                setMaDichVu(e.target.value)
                            }
                            value={maDichVu}
                            error={errorId}
                            helperText={
                                errorId
                                    ? t("validate") +
                                    " " +
                                    t("res.code").toLowerCase()
                                    : ""
                            }
                            required
                            fullWidth
                            label={t("res.code")}
                        ></InputMui>

                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={10}>
                        <SingleSelectMui
                            value={nccLoaiCabinId}
                            error={errorNccLoaiCabinId}
                            helperText={
                                errorNccLoaiCabinId
                                    ? t("validate") +
                                    " " +
                                    t("roomType").toLowerCase()
                                    : ""
                            }
                            required
                            fullWidth
                            onChange={(e) =>
                                setNccLoaiCabinId(e.target.value)
                            }
                            options={hangPhong}
                            label={t("roomType")}
                        >
                        </SingleSelectMui>

                    </Col>
                </Row>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={10}>
                        <InputMui
                            sx={{
                                marginBottom: 2,
                            }}
                            value={tenDichVu}
                            error={errorTenDichVu}
                            onChange={(e) =>
                                setTenDichVu(e.target.value)
                            }
                            helperText={
                                errorTenDichVu
                                    ? t("validate") +
                                    " " +
                                    t(
                                        "res.serviceProvided"
                                    ).toLowerCase()
                                    : ""
                            }
                            required
                            fullWidth
                            label={t("res.serviceProvided")}
                        ></InputMui>
                    </Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={1}></Col>
                    <Col xs={11} sm={11} md={11} lg={11} xl={10}>
                        <SingleSelectMui
                            value={dmTyGiaId}
                            required
                            fullWidth
                            options={tiGia}
                            onChange={(e) =>
                                setDmTyGiaId(e.target.value)
                            }
                            label={t("exchangeRate.currency")}
                        >
                        </SingleSelectMui>

                    </Col>
                </Row>
            </>
        );
    };

    const formItemLayoutDate = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };
    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 10,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 14,
            },
        },
    };
    const handleClear = () => {
        form.resetFields();
    };
    return (
        <div>
            <Modal
                title={action ? t("edit") + " " + t("informationPriceRoom").toLowerCase() : t("informationPriceRoom")}
                open={isOpen}
                // onOk={onFinish}
                onCancel={handleCancels}
                footer={null}
                width={1200}
            >
                <Divider />
                {dataRenderMui()}
                <Form
                    form={form}
                    onFinish={onFinish}
                    colon={false}
                    {...formItemLayout}
                    initialValues={{
                        remember: true,
                    }}
                >
                    {dataRender()}

                    {action ? (
                        <Form.Item
                            wrapperCol={{
                                span: 20,
                                offset: 6,
                            }}
                        >
                            <Space>
                                <Affix offsetBottom={50}>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            color: "#fff",
                                            height: 60,
                                            paddingInline: 50,
                                            lineHeight: "60px",
                                            backgroundColor: "rgba(150, 150, 150, 0.2)",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        <Space>
                                            <Button variant="contained" type="submit" startIcon={<Save/>}>
                                                {t("action.ok")}
                                            </Button>
                                            <Button
                                                sx={{
                                                    backgroundColor: "#898989",
                                                    color: "white",
                                                }}
                                                key="reset"
                                                startIcon={<RestartAlt/>}
                                                onClick={(e) => handleClear(e)}
                                            >
                                                {t("action.reset")}
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                startIcon={<Delete/>}
                                                onClick={handleCancels}
                                                key="clear"
                                            >
                                                {t("action.cancel")}
                                            </Button>
                                        </Space>
                                    </div>
                                </Affix>
                            </Space>
                        </Form.Item>
                    ) : (
                        ""
                    )}
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPrAction;
