import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { API_BASE_URL, TIME_DURATION } from "../../../constants";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import authHeader from "../../../api/auth_header";
import { notification, Space, Row, Col, Typography, Switch } from "antd";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import TableMui from "../../layout/TableMui";
import FormActionButtonsFirst from "../common/formActionButton/FormActionButtonsFirst";

const SpLandTour = ({ keySide, nameKey }) => {
    const [dataSource, setDataSource] = useState([]);
    const { t } = useTranslation();
    const [api, contextHolder] = notification.useNotification();
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [action, setAction] = useState(false);
    const isEditing = (record) => record.key === editingKey;
    // const [dataSearch, setDataSearch] = useState();

    const navigate = useNavigate();
    const edit = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(true);
        direct(record, "edit");
    };
    useEffect(() => {
        if (keySide !== undefined && keySide.toString().length > 0) {
            let data = {
                dmDichVuId: keySide,
            };
            axios({
                method: "post",
                url: API_BASE_URL + "nnc/thongtinchung/getData",
                data: data,
                headers: authHeader(),
            })
                .then(function (response) {
                    console.log("Response: ====" + response.status);
                    if (response.status === 200) {
                        let data = response.data.data;
                        let dataArr = [];
                        // if (data.length > 0) {
                        //     for (let i = 0; i < data.length; i++) {
                        //         dataArr.push({
                        //             stt: i + 1,
                        //             id: data[i].id,
                        //             maNcc: data[i].maNcc,
                        //             tenNcc: data[i].tenNcc,
                        //             email: data[i].email,
                        //             sdt: data[i].sdt,
                        //             diaChi: data[i].diaChi,
                        //             trangThai: data[i].trangThai,
                        //             tongSoDichVu: data[i].tongSoDichVu,
                        //         });
                        //     }
                        // }
                        setDataSource(data);
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
    }, [keySide]);

    const direct = (record, status) => {
        switch (keySide) {
            case 13:
                return navigate(
                    "/ncc/land-tour/" +
                        status +
                        "?id=" +
                        record.id +
                        "&key=" +
                        keySide
                );
            default:
                return null;
        }
    };
    // const viewRecord = (record) => {
    //     setEditingRecord(record);
    //     setIsShow(true);
    //     direct(record, "view");
    // };
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            headerAlign: "center",
            align: "center",
            renderCell: (params) =>
                params.api.getAllRowIds().indexOf(params.id) + 1,
        },
        {
            headerName: t("supply.code"),
            field: "maNcc",
            key: "maNcc",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("supply.name"),
            field: "tenNcc",
            key: "tenNcc",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("totalService"),
            field: "tongSoDichVu",
            key: "tongSoDichVu",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("email"),
            field: "email",
            key: "email",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("phone"),
            field: "sdt",
            key: "sdt",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("address"),
            field: "diaChi",
            key: "diaChi",
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("status"),
            field: "trangThai",
            key: "trangThai",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: ({ value, row }) => {
                return (
                    <Switch
                        defaultValue={value}
                        onChange={() => handleBorderChange(row)}
                    />
                );
            },
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Typography.Link
                        disabled={editingKey !== ""}
                        onClick={() => edit(record.row)}
                    >
                        <EditOutlined style={{}} />
                    </Typography.Link>
                );
            },
            // align: "center",
        },
    ];
    const handleBorderChange = (record) => {
        let values = {
            id: record.id,
            hieu_luc: record.hieu_luc === true ? false : true,
            trangThai: record.trangThai === true ? false : true,
        };
        apiEdit(values, "trangThai");
    };
    const apiEdit = (values, name_key) => {
        axios({
            method: "post",
            url: API_BASE_URL + "nnc/thongtinchung/updateStatus",
            data: values,
            headers: authHeader(),
        }).then(function (response) {
            if (response.status === 200 && response.data.code === "00") {
                let updatedList = dataSource.map((item) => {
                    if (item.id === values.id) {
                        if (name_key === "trangThai") {
                            return { ...item, trangThai: values.trangThai };
                        } else {
                            return { ...item, hieu_luc: values.hieu_luc };
                        }
                    }
                    return item; // else return unmodified item
                });
                setDataSource(updatedList);
                api["success"]({
                    message: t("stour"),
                    description:
                        t("edit") + " " + t("noti.success").toLowerCase(),
                    duration: TIME_DURATION,
                });
            }
        });
    };
    // const handleDelete = (record) => {
    //     const newData = dataSource.filter((item) => item.key !== record.key);
    //     let apiData = {
    //         id: record.id,
    //         trangThai: record.trangThai,
    //         hieuLuc: record.hieuLuc === true ? false : true,
    //     };
    //     axios({
    //         method: "post",
    //         url: API_BASE_URL + "/nnc/thongtinchung/updateStatus",
    //         data: apiData,
    //         headers: authHeader(),
    //     })
    //         .then(function (response) {
    //             if (response.status === 200) {
    //                 api["success"]({
    //                     message: t("stour"),
    //                     description: t("delete") + " " + t("noti.success").toLowerCase(),
    //                     duration: TIME_DURATION,
    //                 });
    //             } else {
    //                 api["error"]({
    //                     message: t("stour"),
    //                     description: t("delete") + " " + t("noti.error").toLowerCase() + " " + response.data.message,
    //                     duration: TIME_DURATION,
    //                 });
    //             }
    //         })
    //         .catch(
    //             api["error"]({
    //                 message: t("stour"),
    //                 description: t("add") + " " + t("noti.error").toLowerCase(),
    //                 duration: TIME_DURATION,
    //             })
    //         );
    //     setDataSource(newData);
    // };
    const QuickSearchToolbar = () => {
        return (
            <Box
                onClick={(e) => {
                    e.preventDefault();
                }}
            >
                <Row>
                    <Col xs={6} sm={6} md={6} lg={8} xl={6}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={4} sm={6} md={8} lg={4} xl={10}></Col>
                    <Col xs={6} sm={6} md={6} lg={12} xl={8}>
                        <FormActionButtonsFirst
                            keySide={keySide}
                            dichVu="landTour" // yacht: du thuyền
                        />
                    </Col>
                </Row>
            </Box>
        );
    };
    return (
        <div>
            {contextHolder}
            <TableMui
                dataSource={dataSource}
                columns={columns}
                edit={edit}
                QuickSearchToolbar={QuickSearchToolbar}
            />
        </div>
    );
};
export default SpLandTour;
