import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Col, Popconfirm, Row, Tooltip, Typography, notification } from "antd";
import Edit from "@mui/icons-material/Edit";
import Delete from "@mui/icons-material/Delete";
import ModalChietTinhCommon from "../modal/ModalChietTinhCommon";
import {
  DV_KS,
  DV_NH,
  NH_AN_SANG,
  NH_AN_TOI,
  NH_AN_TRUA,
  NH_Buffet,
  TIME_DURATION,
} from "../../../../../constants";
import { ReactComponent as BFIcon } from "../../../../../assets/img/chiettinh/BF.svg";
import { ReactComponent as DIcon } from "../../../../../assets/img/chiettinh/D.svg";
import { ReactComponent as GDIcon } from "../../../../../assets/img/chiettinh/GD.svg";
import { ReactComponent as LIcon } from "../../../../../assets/img/chiettinh/L.svg";
import nhaHangService from "../../../../../api/tour/nhaHang/nhaHangService";
import khachSanService from "../../../../../api/tour/khachSan/khachSanService";
import huongDanVienService from "../../../../../api/tour/huongDanVien/huongDanVienService";
import veMayBayService from "../../../../../api/tour/veMayBay/veMayBayService";
import veThuyenService from "../../../../../api/tour/veThuyen/veThuyenService";
import visaService from "../../../../../api/tour/visa/visaService";
import veThangCanhService from "../../../../../api/tour/veThangCanh/veThangCanhService";
import veBusService from "../../../../../api/tour/veBus/veBusService";
import veTauService from "../../../../../api/tour/veTau/veTauService";

const { Link } = Typography;

const DichVuCommon = ({dataSource,
    tongSoNgay,
    danhSachKhoangKhach,
    tongChietTinhResponse,
    tyGia,
    onReset,
    tourChuongTrinh,
    tourThongTinChungId,
    tourThoiGianApDungId,
    tourChietTinhId,
    tabChietTinhId,
    loaiTong,
    tourQuanLyKhoangKhachId,
    thoiGianApDung,
    tranxChietTinhId,
    chietTinh,
    stt,
    title,
  }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [tyGiaTab, setTyGiaTab] = React.useState(0);
  const [api, contextHolder] = notification.useNotification();
  const [tourMapChietTinhChuongTrinhId, setTourMapChietTinhChuongTrinhId] =
    useState(0);
  const [tranxDichVuId, setTranxDichVuId] = useState(0);
  const [isAdd, setIsAdd] = useState(false);
  const [tongGiaDichVu, setTongGiaDichVu] = useState({});
  const [day, setDay] = useState();
  const [tourChuongTrinhId, setTourChuongTrinhId] = useState();
  const [checkThemMoi, setcheckThemMoi] = useState(true);
  const [thoiGianAD, setThoiGianAD] = useState(null);
  const columns = [
    {
      headerName: t("day"),
    },
    {
      headerName: t("supplier"),
    },
    {
      headerName: t("service"),
    },
    {
      headerName: t("tour.donGia"),
    },
    {
      headerName: t("tour.chiPhiTreEm"),
    },
  ];
  const [items, setItems] = useState([]);
  const [columnTb, setColumnTb] = useState([]);
  const [column, setColumn] = useState([]);
  const [sums, setSums] = useState({
    id: "SUBTOTAL",
    label: t("notes"),
    taxRate: t("tour.tong"),
    donGia: 0,
    chiPhiTreEm: 0,
  });
  const [idDsKhoangKhach, setIdDsKhoangKhach] = useState([]);
  const [dsDichVuByDay, setDsDichVuByDay] = useState([]);
  const dvExists = (arr, tenDichVu) => {
    return arr.some(function (el) {
      return el.tenDichVu === tenDichVu;
    });
  };
  useEffect(() => {
    const initialize = () => {
        setcheckThemMoi(!(tourQuanLyKhoangKhachId !== 0 && tourThoiGianApDungId !== 0));
        setTyGiaTab(tyGia);

        if (tongSoNgay) {
            if(tourThoiGianApDungId && thoiGianApDung?.length > 0){
              let tg = thoiGianApDung.find(
                (item) => item.id === tourThoiGianApDungId
              );
              if(tg)
                setThoiGianAD({
                  id: tg.id,
                  tourChietTinhId: tg.tourChietTinhId,
                  tuNgay: tg.tuNgay,
                  denNgay: tg.denNgay,
                });
            }
            const generatedDays = generateDays(tongSoNgay);
            setItems(generatedDays);
        }

        if (tongChietTinhResponse) {
            setSums({
                id: "SUBTOTAL",
                label: t("notes"),
                taxRate: t("tour.tong"),
                donGia: 0,
                chiPhiTreEm: tongChietTinhResponse?.chiPhiTreEm,
                tongGiaDichVu: tongChietTinhResponse?.tongGiaDichVu,
            });
        }

        if (danhSachKhoangKhach?.length > 0) {
            const { column, idKhoangKhach, tongGiaDichVuInit } = prepareColumns(danhSachKhoangKhach);
            setColumn(column);
            setColumnTb([...columns, ...column]);
            setIdDsKhoangKhach(idKhoangKhach);
            setTongGiaDichVu(tongGiaDichVuInit);
        }
    };

    const generateDays = (totalDays) => {
        let days = [];
        for (let i = 1; i <= totalDays; i++) {
            const tourCT = tourChuongTrinh.find((ct) => parseInt(ct.ngay) === i);
            if (tourCT && dvExists(tourCT.dmDichVus, DV_KS)) {
                days = [...days, ...createItemsForDay(tourCT, i)];
            }
        }
        return days;
    };

    const createItemsForDay = (tourCT, dayIndex) => {
        const source = dataSource?.find((dt) => parseInt(dt.ngay) === dayIndex);
        const items = [];
        let id = dayIndex;

        if (source?.nhaCungCaps?.length > 0) {
            source.nhaCungCaps.forEach((element, k) => {
                items.push({
                    ...element,
                    id: id++,
                    item: dayIndex,
                    tourChuongTrinhId: tourCT.id,
                    rowSpan: k === 0 ? source.nhaCungCaps.length + 1 : 0,
                });
            });
        }

        items.push({
            id: "ADD_NEW" + id,
            item: dayIndex,
            tourChuongTrinhId: tourCT.id,
            rowSpan: source ? 0 : 1,
        });

        return items;
    };

    const prepareColumns = (khoangKhachList) => {
        const column = [];
        const idKhoangKhach = [];
        const tongGiaDichVuInit = {};

        khoangKhachList.forEach((khach) => {
            const columnKey = "giaDichVu" + khach.id;
            if (!column.find((c) => c.field === columnKey)) {
                column.push({
                    headerName:
                        khach.dmLoaiKhachId === 1
                            ? `${khach.soKhachToiThieu}Pax`
                            : `${khach.soKhachToiThieu}-${khach.soKhachToiDa}Pax`,
                });
            }
            idKhoangKhach.push(khach.id);
            tongGiaDichVuInit[khach.id] = 0;
        });

        return { column, idKhoangKhach, tongGiaDichVuInit };
    };

    initialize();
}, [tyGia, tyGiaTab, tourChuongTrinh]);

  const rows = [...items, sums];

  const onSave = () => {
    setIsOpen(false);
    onReset();
  };

  const handleEditServices = (data) => {
    console.log(data);
    setIsAdd(false);
    setTourMapChietTinhChuongTrinhId(data.tourMapChietTinhChuongTrinhId);
    setTranxDichVuId(data[tranxChietTinhId]); //nhaHang: tranxChietTinhNhaHangId, ks: tranxKhachSanId
    setTourChuongTrinhId(data.tourChuongTrinhId);
    setDay(data.item);
    setIsOpen(true);
  };

  const handleCancelModal = () => {
    setIsOpen(false);
  };

  const handleAddServices = (data) => {
    console.log(items);
    const dsDichVuByDay = items.filter((it) => it.item === data.item);
    setDsDichVuByDay(dsDichVuByDay);
    setTourChuongTrinhId(data.tourChuongTrinhId);
    setDay(data.item);
    setIsAdd(true);
    setIsOpen(true);
  };

  const handleDeleteServices = (data) => {
    const serviceMap = {
      tranxChietTinhNhaHangId: handleDeleteServicesNhaHang,
      tranxKhachSanId: handleDeleteServicesKhachSan,
      tranxHdvId: handleDeleteServicesHdv,
      tranxMayBayId: handleDeleteServicesVeMayBay,
      tranxVeThuyenId: handleDeleteServicesVeThuyen,
      tranxVisaId: handleDeleteServicesVisa,
      tranxVeThangCanhId: handleDeleteServicesVeThangCanh,
      tranxBusId: handleDeleteServicesVeBus,
      tranxVeTauId: handleDeleteServicesVeTau,
    };
  
    const deleteService = serviceMap[tranxChietTinhId];
    if (deleteService) {
      deleteService(data);
    } else {
      console.error("Service not found for tranxChietTinhId:", tranxChietTinhId);
    }
  };

  const handleDeleteServicesKhachSan = (data) => {
    handleDeleteServiceCommon(data, khachSanService, "xoaCauHinhKhachSan");
  };
  
  const handleDeleteServicesNhaHang = (data) => {
    handleDeleteServiceCommon(data, nhaHangService, "xoaCauHinhNhaHang");
  };

  const handleDeleteServicesHdv = (data) => {
    handleDeleteServiceCommon(data, huongDanVienService, "xoaCauHinhHdv");
  };

  const handleDeleteServicesVeMayBay = (data) => {
    handleDeleteServiceCommon(data, veMayBayService, "xoaCauHinhVeMayBay");
  };

  const handleDeleteServicesVeThuyen = (data) => {
    handleDeleteServiceCommon(data, veThuyenService, "xoaCauHinhVeThuyen");
  };

  const handleDeleteServicesVisa = (data) => {
    handleDeleteServiceCommon(data, visaService, "xoaCauHinhVisa");
  };

  const handleDeleteServicesVeThangCanh = (data) => {
    handleDeleteServiceCommon(data, veThangCanhService, "xoaCauHinhVeThangCanh");
  };

  const handleDeleteServicesVeBus = (data) => {
    handleDeleteServiceCommon(data, veBusService, "xoaCauHinhVeBus");
  };

  const handleDeleteServicesVeTau = (data) => {
    handleDeleteServiceCommon(data, veTauService, "xoaCauHinhVeTau");
  };

  const handleDeleteServiceCommon = async (data, service, deleteFunction) => {
    try {
      const dataReq = { tourMapChietTinhChuongTrinhId: data.tourMapChietTinhChuongTrinhId };
      const response = await service[deleteFunction](dataReq);
  
      if (response.status === 200) {
        updateStateAfterDelete(data);
        showNotification("success", t("delete") + " " + t("noti.success").toLowerCase());
      } else {
        showNotification(
          "error",
          t("delete") +
            " " +
            t("noti.error").toLowerCase() +
            " " +
            (response.data?.message || "")
        );
      }
    } catch (error) {
      showNotification("error", t("delete") + " " + t("noti.error").toLowerCase());
    }
  };  

  const updateStateAfterDelete = (data) => {
    let isCheck = true;
    let sum = rows.find((item) => item.id === "SUBTOTAL");
    let lstNcc = items.filter(
      (item) =>
        item.id !== data.id &&
        item.item === data.item &&
        item.tourMapChietTinhChuongTrinhId
    );
    let lstItems = items.filter((item) => item.id !== data.id);
    let tongGiaDV = { ...tongGiaDichVu };
    let donGia = 0;
    let chiPhiTreEm = 0;
  
    lstItems.forEach((item) => {
      if (
        isCheck &&
        (item.tourMapChietTinhChuongTrinhId ||
          (lstNcc.length === 0 && item.item === data.item))
      ) {
        item.rowSpan = lstNcc.length + 1;
        isCheck = false;
      }
      if (item.donGia) donGia += item.donGia;
      if (item.tongChiPhiTreEm) chiPhiTreEm += item.chiPhiTreEm;
      if (item?.values?.length > 0 && idDsKhoangKhach.length > 0) {
        idDsKhoangKhach.forEach((id) => {
          item.values.forEach((value) => {
            if (value.tourQuanLyKhoangKhachId === id) tongGiaDV[id] += value.giaDichVu;
          });
        });
      }
    });
  
    sum.tongGiaDichVu = tongGiaDV;
    sum.donGia = donGia;
    sum.chiPhiTreEm = chiPhiTreEm;
    setSums(sum);
    setItems(lstItems);
  };  

  const showNotification = (type, description) => {
    api[type]({
      message: t("stour"),
      description,
      duration: TIME_DURATION,
    });
  };

  const handleDv = (value) => {
    const iconMap = {
      [NH_AN_SANG]: <BFIcon className="css-class" />,
      [NH_AN_TRUA]: <LIcon className="css-class" />,
      [NH_AN_TOI]: <DIcon className="css-class" />,
      [NH_Buffet]: <GDIcon className="css-class" />,
    };
  
    const IconComponent = iconMap[value];
    return IconComponent ? (
      <>
        {IconComponent}
        &nbsp; &nbsp; {value}
      </>
    ) : value;
  };  

  const isNumeric = (str) => {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); 
  };

  const handleData = (value) => {
    let number = 0;
    if (value) {
      number = parseFloat(value);
      if (isNumeric(tyGiaTab)) {
        number = parseFloat(value) * parseFloat(tyGiaTab);
      }
    }
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const renderSubTotalRow = (it) => (
    <tr>
      <td colSpan={3}>{it.label}</td>
      <td>{it.taxRate}</td>
      <td>{it.chiPhiTreEm}</td>
      {idDsKhoangKhach.length > 0
        ? idDsKhoangKhach.map((item) => (
            <td key={item}>{handleData(it.tongGiaDichVu?.[item] || 0)}</td>
          ))
        : null}
    </tr>
  );

  const renderDataRow = (it) => (
    <tr>
      {it.rowSpan ? <td rowSpan={it.rowSpan}>{it.item}</td> : null}
      {it.maNcc ? (
        <>
          <td>
            <Tooltip placement="topLeft" title={it.maNcc}>
              {it.tenNcc}
            </Tooltip>
          </td>
          <td>
            <Row>
              <Col xs={21} sm={21} md={21} lg={21} xl={20}>
                {handleDv(it.dichVu)}
              </Col>
              {it.values?.length > 0 && (
                <Col>
                  <Edit fontSize="small" onClick={() => handleEditServices(it)} />
                  <Popconfirm
                    title={t("deleteTheTask")}
                    description={t("areYouConfirm")}
                    okText={t("delete")}
                    cancelText={t("huy")}
                    onConfirm={() => handleDeleteServices(it)}
                  >
                    <Delete fontSize="small" />
                  </Popconfirm>
                </Col>
              )}
            </Row>
          </td>
          <td>
            {it.parentTranxChietTinhNhaHangId === 0
              ? handleData(it.donGia)
              : it.ngayOrder}
          </td>
          <td>
            {it.values?.length > 0
              ? handleData(it.tongChiPhiTreEm)
              : it.maNcc}
          </td>
          {it.values?.length > 0
            ? it.values.map((item, index) => (
                <td key={index}>{handleData(item.giaDichVu)}</td>
              ))
            : column?.map((item, index) => (
                <td key={index}>{it.maNcc}</td>
              ))}
        </>
      ) : (
        <td colSpan={8}>
          <Link onClick={() => handleAddServices(it)} disabled={checkThemMoi}>
            {t("add") + " " + t("service").toLowerCase()}
          </Link>
        </td>
      )}
    </tr>
  );

  return (
    <Box
      sx={{
        width: "100%",
        "& .bold": {
          fontWeight: 400,
          color: "rgba(0, 0, 0, 0.6)",
        },
        "& .header-name": {
          backgroundColor: "#f7f7f7",
          fontWeight: 600,
        },
      }}
    >
      {contextHolder}
  
      <ModalChietTinhCommon
        isOpen={isOpen}
        isAdd={isAdd}
        onSave={onSave}
        onCancel={handleCancelModal}
        chietTinh={chietTinh}
        tourMapChietTinh={tourMapChietTinhChuongTrinhId}
        tranxChietTinh={tranxDichVuId}
        tourChuongTrinhId={tourChuongTrinhId}
        tourChuongTrinh={tourChuongTrinh}
        day={day}
        tourThongTinChungId={tourThongTinChungId}
        tourThoiGianApDungId={tourThoiGianApDungId}
        tourChietTinhId={tourChietTinhId}
        tabChietTinhId={tabChietTinhId}
        loaiTong={loaiTong}
        thoiGianAD={thoiGianAD}
        dsDichVuByDay={dsDichVuByDay}
      />
  
      <table>
        <thead>
          <tr>
            <th style={{ width: "50px", backgroundColor: "#d9d9d9" }}>{stt}</th>
            <th colSpan="100%" style={{ textAlign: "left", textIndent: "20px", backgroundColor: "#d9d9d9" }}>
              {title}
            </th>
          </tr>
          <tr>
            {columnTb.map((cl) => (
              <th key={cl.headerName}>{cl.headerName}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((it) => (
            <React.Fragment key={it.id}>
              {it.id === "SUBTOTAL" ? renderSubTotalRow(it) : renderDataRow(it)}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </Box>
  );
  
}
export default DichVuCommon;
