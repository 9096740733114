import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const getCauHinhVeTauTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/layCauHinhVeTauTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const updateCauHinhVeTauTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/updateCauHinhVeTauTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const saveCauHinhVeTau = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/saveCauHinhVeTau", data, {
    headers: authHeader(),
  });
};

const getQuocGia = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachQuocGia", data, {
    headers: authHeader(),
  });
};

const getTinhTP = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachTinhThanhPho", data, {
    headers: authHeader(),
  });
};

const getNccVeTau = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getNccVeTau", data, {
    headers: authHeader(),
  });
};

const xoaCauHinhVeTau = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/xoaCauHinhVeTau", data, {
    headers: authHeader(),
  });
};

export default {
  getCauHinhVeTauTheoMap,
  updateCauHinhVeTauTheoMap,
  saveCauHinhVeTau,
  getQuocGia,
  getTinhTP,
  getNccVeTau,
  xoaCauHinhVeTau,
};
