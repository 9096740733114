import { DataGrid, enUS, viVN } from "@mui/x-data-grid";
import i18n from "../../i18n";
import React, { useMemo } from "react";

const TableMui = ({
    dataSource,
    columns,
    QuickSearchToolbar,
    edit,
    columnGroupingModel = null,
    footer = undefined,
    ...rest
}) => {
    const uniqueDataSource = useMemo(() => {
        const uniqueRows = [];
        const uniqueKeys = new Set();
        if (!dataSource) return uniqueRows;
        dataSource?.forEach((row) => {
            const uniqueKey =
                row.maDichVu || row.tenDichVu || row.thoiGianApDung
                    ? `${row.maDichVu}-${row.tenDichVu}-${row.thoiGianApDung}`
                    : `${row.id || Math.random().toString(36)}`;

            if (!uniqueKeys.has(uniqueKey)) {
                uniqueKeys.add(uniqueKey);
                uniqueRows.push(row);
            }
        });

        return uniqueRows;
    }, [dataSource]);

    return (
        <DataGrid
            sx={{
                "& .MuiDataGrid-cell": {
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                },
                "& .MuiDataGrid-row": {
                    borderBottom: "1px solid rgba(224, 224, 224, 1)",
                },
                "& .MuiDataGrid-columnHeader": {
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    backgroundColor: "#e2e2e2",
                    marginTop: "10px",
                },
                "& .MuiDataGrid-columnHeaderTitle ": {
                    fontWeight: "bold",
                },
                "& .MuiDataGrid-cellContent": {
                    fontSize: "13px",
                },
                "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                },
            }}
            unstable_rowSpanning={true}
            columnGroupingModel={columnGroupingModel}
            onRowDoubleClick={(record) => edit(record.row)}
            rows={uniqueDataSource}
            columns={columns}
            slots={{
                toolbar: QuickSearchToolbar,
                footer: footer,
            }}
            autoHeight
            {...dataSource}
            initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            localeText={
                i18n.language === "vi"
                    ? viVN.components.MuiDataGrid.defaultProps.localeText
                    : enUS
            }
            {...rest}
            getRowId={(row) => row.ids || row.id || row.rowKey}
        />
    );
};
export default TableMui;
