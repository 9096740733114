import React, { useEffect, useState } from "react";
import { Typography, notification, Space, Popconfirm, Col, Row } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { API_BASE_URL, TIME_DURATION } from "../../../../../constants";
import authHeader from "../../../../../api/auth_header";
import ModalHpAction from "./ModalHpAction";
import { Box } from "@mui/material";
import { GridToolbarQuickFilter } from "@mui/x-data-grid";
import TableMui from "../../../../layout/TableMui";
import FormActionButtonSecond from "../../../common/formActionButton/FormActionButtonSecond ";
const RoomClass = ({
    dvName,
    dvId,
    isView,
    QuickSearchToolbar: QuickSearchToolbarProp,
}) => {
    const [dataSource, setDataSource] = useState([]);
    const { t } = useTranslation();
    const [editingKey, setEditingKey] = useState("");
    const [editingRecord, setEditingRecord] = useState("");
    const [isShow, setIsShow] = useState(false);
    // const [dataSearch, setDataSearch] = useState();
    const [action, setAction] = useState(false);
    const [isReload, setIsReload] = useState(false);
    const isEditing = (record) => record.key === editingKey;
    const edit = (record, status) => {
        setEditingRecord(record);
        setIsShow(true);
        if (status === "edit") {
            setAction(true);
        }
    };
    const viewRecord = (record) => {
        setEditingRecord(record);
        setIsShow(true);
        setAction(false);
    };
    const [api, contextHolder] = notification.useNotification();
    useEffect(() => {
        if (isReload) getData();
    }, [isReload]);
    const handleDelete = (record) => {
        const newData = dataSource.filter((item) => item.key !== record.key);
        let apiData = {
            id: record.id,
            hieuLuc: record.hieuLuc != true,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/hangphong/updateStatus",
            data: apiData,
            headers: authHeader(),
        })
            .then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                    setIsReload(true);
                } else {
                    api["error"]({
                        message: t("stour"),
                        description:
                            t("delete") +
                            " " +
                            t("noti.error").toLowerCase() +
                            " " +
                            response.data.message,
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(
                api["error"]({
                    message: t("stour"),
                    description: t("add") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                })
            );

        setDataSource(newData);
    };
    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        let data = {
            nccThongTinChungId: dvId,
        };
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/hangphong/getData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    // let dataLstSearch = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                stt: i + 1,
                                id: data[i].id,
                                nccThongTinChungId: data[i].nccThongTinChungId,
                                hangPhong: data[i].hangPhong,
                                soLuongPhong: data[i].soLuongPhong,
                                soKhachToiDa: data[i].soKhachToiDa,
                                kichThuocPhong: data[i].kichThuocPhong,
                                dieuKienFoc: data[i].dieuKienFoc,
                                foc: data[i].foc,
                                ghiChu: data[i].ghiChu,
                            });
                            // dataLstSearch.push({
                            //     value:
                            //         data[i].id +
                            //         " - " +
                            //         data[i].nccThongTinChungId +
                            //         " - " +
                            //         data[i].hangPhong +
                            //         " - " +
                            //         data[i].soLuongPhong +
                            //         " - " +
                            //         data[i].soKhachToiDa +
                            //         " - " +
                            //         data[i].kichThuocPhong +
                            //         " - " +
                            //         data[i].dieuKienFoc +
                            //         " - " +
                            //         data[i].foc +
                            //         " - " +
                            //         data[i].ghiChu,
                            // });
                        }
                        // setDataSearch(dataLstSearch);
                        setDataSource(dataLst);
                        setIsReload(false);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    };
    // const onSearch = (value) => {
    //     let data = [];
    //     for (let i in dataSearch) {
    //         if (dataSearch[i].value.toString().toLowerCase().includes(value.toLowerCase())) {
    //             let item = dataSearch[i].value.split(" - ");
    //             data.push({
    //                 stt: i,
    //                 id: item[0],
    //                 nccThongTinChungId: item[1],
    //                 hangPhong: item[2],
    //                 soLuongPhong: item[3],
    //                 soKhachToiDa: item[4],
    //                 kichThuocPhong: item[5],
    //                 dieuKienFoc: item[6],
    //                 foc: item[7],
    //                 ghiChu: item[8],
    //             });
    //         }
    //     }
    //     setDataSource(data);
    // };
    // const [searchText, setSearchText] = useState("");
    // const [searchedColumn, setSearchedColumn] = useState("");
    // const searchInput = useRef(null);
    // const handleSearch = (selectedKeys, confirm, field) => {
    //     confirm();
    //     setSearchText(selectedKeys[0]);
    //     setSearchedColumn(field);
    // };
    // const getColumnSearchProps = (field) => ({
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    //         <div
    //             style={{
    //                 padding: 8,
    //             }}
    //             onKeyDown={(e) => e.stopPropagation()}
    //         >
    //             <Input
    //                 ref={searchInput}
    //                 placeholder={`${t("search")}`}
    //                 value={selectedKeys[0]}
    //                 onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //                 onPressEnter={() => handleSearch(selectedKeys, confirm, field)}
    //                 style={{
    //                     marginBottom: 8,
    //                     display: "block",
    //                 }}
    //             />
    //             <Space>
    //                 <Button
    //                     type="primary"
    //                     onClick={() => handleSearch(selectedKeys, confirm, field)}
    //                     icon={<SearchOutlined />}
    //                     size="small"
    //                 >
    //                     {t("search")}
    //                 </Button>
    //                 <Button onClick={() => clearFilters && handleReset(clearFilters)} size="small">
    //                     {t("action.reset")}
    //                 </Button>
    //                 <Button
    //                     type="link"
    //                     size="small"
    //                     onClick={() => {
    //                         close();
    //                     }}
    //                 >
    //                     {t("close")}
    //                 </Button>
    //             </Space>
    //         </div>
    //     ),
    //     filterIcon: (filtered) => (
    //         <SearchOutlined
    //             style={{
    //                 color: filtered ? "#1677ff" : undefined,
    //             }}
    //         />
    //     ),
    //     onFilter: (value, record) => record[field].toString().toLowerCase().includes(value.toLowerCase()),
    //     onFilterDropdownOpenChange: (visible) => {
    //         if (visible) {
    //             setTimeout(() => searchInput.current?.select(), 100);
    //         }
    //     },
    //     render: (text) =>
    //         searchedColumn === field ? (
    //             <Highlighter
    //                 highlightStyle={{
    //                     backgroundColor: "#ffc069",
    //                     padding: 0,
    //                 }}
    //                 searchWords={[searchText]}
    //                 autoEscape
    //                 textToHighlight={text ? text.toString() : ""}
    //             />
    //         ) : (
    //             text
    //         ),
    // });
    // const handleReset = (clearFilters) => {
    //     clearFilters();
    //     setSearchText("");
    // };
    const columns = [
        {
            headerName: t("stt"),
            field: "stt",
            key: "stt",
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.roomClass"),
            field: "hangPhong",
            key: "hangPhong",

            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.roomClass.numberOfRoom"),
            field: "soLuongPhong",
            key: "soLuongPhong",

            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.roomClass.numberOfGuests1Room"),
            field: "soKhachToiDa",
            key: "soKhachToiDa",

            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("hotel.roomClass.conditionFoc"),
            field: "dieuKienFoc",
            key: "dieuKienFoc",

            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("notes"),
            field: "ghiChu",
            key: "ghiChu",

            flex: 1,
            headerAlign: "center",
            align: "center",
        },
        {
            headerName: t("action"),
            field: "action",
            key: "x",
            responsive: ["md"],
            renderCell: (record) => {
                const editable = isEditing(record.row);
                return (
                    <Space size="large">
                        <Typography.Link>
                            <EyeOutlined
                                onClick={() => viewRecord(record.row)}
                            />
                        </Typography.Link>
                        <Typography.Link
                            disabled={editingKey !== ""}
                            onClick={() => edit(record.row, "edit")}
                        >
                            <EditOutlined style={{}} />
                        </Typography.Link>
                        <Typography.Link>
                            <Popconfirm
                                headerName={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDelete(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    </Space>
                );
            },
            flex: 1,
            headerAlign: "center",
            align: "center",
        },
    ];
    const handleCancel = () => {
        setIsShow(false);
    };
    const handleFinish = (status, values) => {
        setIsShow(status);
        setAction(status);
        let data = {
            ...values,
            id: values.id,
            nccThongTinChungId: dvId,
            kichThuocPhong:
                values.kichThuocPhong !== undefined
                    ? values.kichThuocPhong
                    : "",
            foc: values.foc !== undefined ? values.foc : "",
            ghiChu: values.ghiChu !== undefined ? values.ghiChu : "",
            dmTienIchList:
                values.dmTienIchList !== undefined ? values.dmTienIchList : [],
        };
        console.log("data edit=====", data);
        axios({
            method: "post",
            url: API_BASE_URL + "ncc/hangphong/saveData",
            data: data,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("response=====", response);
                if (response.status === 200 && response.data.code === "00") {
                    let updatedList = dataSource.map((item) => {
                        if (item.id === values.id) {
                            return values;
                        }
                        return item;
                    });
                    setIsReload(true);
                    setDataSource(updatedList);
                    api["success"]({
                        message: t("stour"),
                        description:
                            t("edit") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description:
                        t("edit") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
            });
    };
    // const direct = (record, status) => {
    //     edit(record);
    // };
    const onSetData = () => {
        getData();
    };
    const DefaultQuickSearchToolbar = () => {
        return (
            <Box>
                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <GridToolbarQuickFilter style={{ width: "100%" }} />
                    </Col>
                    <Col xs={4} sm={6} md={8} lg={6} xl={10}></Col>
                    <Col xs={6} sm={6} md={6} lg={12} xl={8}>
                        <FormActionButtonSecond
                            dvId={dvId}
                            dataAdd={onSetData}
                            setReload={setIsReload}
                            dichVu="hotel.roomClass"
                            isDichVuKhac={false}
                        />
                    </Col>
                </Row>
            </Box>
        );
    };

    const QuickSearchToolbar =
        QuickSearchToolbarProp || DefaultQuickSearchToolbar;
    return (
        <div>
            {contextHolder}
            <TableMui
                dataSource={dataSource}
                columns={columns}
                edit={edit}
                QuickSearchToolbar={QuickSearchToolbar}
            />
            <ModalHpAction
                isOpen={isShow}
                handleCancel={handleCancel}
                handleFinish={handleFinish}
                record={editingRecord}
                action={action}
                dvId={dvId}
            />
        </div>
    );
};
export default RoomClass;
