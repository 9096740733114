import React, { useEffect, useState } from "react";
import { Divider, Modal, Row, Table, notification } from "antd";
import { useTranslation } from "react-i18next";
import nhaHangService from "../../../../../../api/tour/nhaHang/nhaHangService";
import { TIME_DURATION } from "../../../../../../constants";
import "../ModalNcc.css";
import ModalFooter from "../ModalFooter";
import ModalHeader from "../ModalHeader";
import veThuyenService from "../../../../../../api/tour/veThuyen/veThuyenService";


const ModalNccVeThuyen = ({
  isOpen,
  onSaves,
  onIsShow,
  thoiGianAD,
}) => {
  const { t } = useTranslation();
  const [quocGiaId, setQuocGiaId] = useState("");
  const [tinhId, setTinhId] = useState("");
  const [supplier, setSupplier] = useState("");
  const [dichVu, setDichVu] = useState("");
  const [api, contextHolder] = notification.useNotification();
  const [selectedRows, setSelectedRows] = useState([]);
  const [select, setSelect] = useState({
    selectedRowKeys: [],
    loading: false,
  });
  const { selectedRowKeys, loading } = select;
  const [dataNccVeThuyen, setDataNccVeThuyen] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState([0, 0]);
  const [dmTyGiaId, setDmTyGiaId] = useState(1);
  const [giaApDungs, setGiaApDungs] = useState([]);

  useEffect(() => {
    if (isOpen)
      getNccVeThuyen({
        searchNcc: "",
        searchDichVu: "",
        dmDichVuId: 7,
        tuNgay: "03/04/2021",
        denNgay: "17/08/2025",
        // tuNgay: thoiGianAD !== null ? thoiGianAD.tuNgay : "",
        // denNgay: thoiGianAD !== null ? thoiGianAD.denNgay : "",
        quocGiaId: 0,
        tinhId: 0,
        giaMin: 0,
        giaMax: 0,
        dmTyGiaId: 0,
      });
  }, [isOpen]);

  const getNccVeThuyen = (data) => {
    veThuyenService
      .getNccVeThuyen(data)
      .then(function (response) {
        if (response.status === 200) {
          let dataNcc =
            response.data.data?.map((item) => ({
              ...item,
              key: item.nccThongTinChungId,
            })) || [];
          console.log("dataNccVeThuyen: ", dataNcc);
          setDataNccVeThuyen(dataNcc);
        }
      })
      .catch(function (response) {
        console.log("Error response: ====" + response);
      });
  };

  const handleData = (value) => {
    let number = 0;
    if (value !== undefined) {
      number = parseInt(value);
    }
    return number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelect({
        ...select,
        selectedRowKeys: selectedRowKeys,
      });
    },
    onSelect: (record, selected, selectedRows) => {
      console.log(record, selected, selectedRows);
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
      setSelectedRows(selectedRows);
    },
  };
  const expandedRowRender = (record) => {
    const columns = [
      {
        title: t("res.code"),
        dataIndex: "maDichVu",
        key: "maDichVu",
        width: "10%",
        align: "center",
      },
      {
        title: t("res.serviceProvided"),
        dataIndex: "tenDichVu",
        key: "tenDichVu",
        width: "12%",
        align: "center",
      },
      {
        title: t("hotel.priceRoom.applicationTime"),
        dataIndex: "thoiGianAD",
        key: "thoiGianAD",
        width: "25%",
        align: "center",
      },
      {
        title: t("hotel.priceClass.giaNet"),
        key: "fitNgayThuongNet",
        dataIndex: "fitNgayThuongNet",
        width: "12%",
        align: "center",
        render: (_, record) => {
          return handleData(record.fitNgayThuongNet);
        },
      },
      {
        title: t("hotel.roomClass.conditionFoc"),
        dataIndex: "dieuKienFoc",
        key: "dieuKienFoc",
        width: "12%",
        align: "center",
      },
      {
        title: t("exchangeRate.currency"),
        dataIndex: "maNgoaiTe",
        key: "maNgoaiTe",
        width: "12%",
        align: "center",
      },
      {
        title: t("tax") + " " + t("vat"),
        dataIndex: "vat",
        key: "vat",
        width: "12%",
        align: "center",
      },
    ];
    let dataDichVus = record?.dichVus?.map((item, index) => {
      return {
        ...item,
        key: item.tranxVeThuyenId,
        thoiGianAD: `${item.tuNgay} - ${item.denNgay}`,
        vat: record.vat,
        dmVatId: record.dmVatId,
        tenNcc: record.tenNcc,
        isVat: record.isVat,
        nccThongTinChungId: record.nccThongTinChungId,
      };
    });
    return (
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataDichVus}
        // pagination={false}
        loading={loading}
      />
    );
  };
  const columns = [
    {
      title: t("maNcc"),
      dataIndex: "maNcc",
      key: "maNcc",
      width: "10%",
      align: "center",
    },
    {
      title: t("supplier"),
      dataIndex: "tenNcc",
      key: "tenNcc",
      width: "20%",
      align: "center",
    },
    {
      title: t("address"),
      dataIndex: "diaChi",
      key: "diaChi",
      width: "30%",
      align: "center",
    },
    {
      title: t("phoneNumber"),
      dataIndex: "sdt",
      key: "sdt",
      width: "20%",
      align: "center",
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
      width: "20%",
      align: "center",
    },
  ];

  const handleCancels = () => {
    onIsShow(false);
    handleResetChooseService();
  };

  const handleCancelChooseService = () => {
    handleCancels();
  };

  const handleResetChooseService = () => {
    setSelect({ selectedRowKeys: [], loading: false });
    setSelectedRows([]);
    setSupplier("");
    setDichVu("");
    setQuocGiaId("");
    setTinhId("");
    setGiaApDungs([]);
  };

  const handleSaveChooseService = () => {
    debugger;
    if (selectedRows?.length === 1) {
      onSaves(selectedRows[0]);
      handleCancels();
      handleNoti("success", t("edit") + " " + t("noti.success").toLowerCase());
    } else {
      handleNoti("error", t("noti.selectServiceError"));
    }
  };

  const handleNoti = (type, description) => {
    return api[type]({
      message: t("stour"),
      description: description,
      duration: TIME_DURATION,
    });
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={t("tour.veThuyen.chonDichVu")}
        open={isOpen}
        onOk={handleSaveChooseService}
        onCancel={handleCancelChooseService}
        footer={null}
        className="custom-modal-ncc"
      >
        <Divider style={{ backgroundColor: "black" }} />
        <ModalHeader
          chietTinh={"veThuyen"}
          supplier={supplier}
          setSupplier={setSupplier}
          dichVu={dichVu}
          setDichVu={setDichVu}
          quocGiaId={quocGiaId}
          setQuocGiaId={setQuocGiaId}
          tinhId={tinhId}
          setTinhId={setTinhId}
          setAnchorEl={setAnchorEl}
          anchorEl={anchorEl}
          dmTyGiaId={dmTyGiaId}
          setDmTyGiaId={setDmTyGiaId}
          giaApDungs={giaApDungs}
          setGiaApDungs={setGiaApDungs}
          value={value}
          setValue={setValue}
          getNccVeThuyen={getNccVeThuyen}
          thoiGianAD={thoiGianAD}
        />
        <Row style={{ width: "100%", marginBottom: "20px" }}>
          <Table
            columns={columns}
            expandable={{
              expandedRowRender,
              defaultExpandedRowKeys: ["0"],
            }}
            dataSource={dataNccVeThuyen}
            style={{ width: "100%" }}
          />
        </Row>
        <ModalFooter onCancelChooseService={handleCancelChooseService} onResetChooseService={handleResetChooseService} onSaveChooseService={handleSaveChooseService} />
      </Modal>
    </>
  );
};
export default ModalNccVeThuyen;
