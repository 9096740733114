import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Space, Divider, Typography, Popconfirm, Switch, notification } from "antd";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import { MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, enUS, viVN } from "@mui/x-data-grid";
import { DeleteOutlined } from "@ant-design/icons";
import FormControlLabel from "@mui/material/FormControlLabel";
import ChietTinhService from "../../../../../api/tour/chiettinh.service";
import CategoryService from "../../../../../api/category.service";
import AddIcon from "@mui/icons-material/Add";
import otoService from "../../../../../api/tour/oto/oto.service";
import { TIME_DURATION } from "../../../../../constants";
const { Text } = Typography;

const ModalQuanLyKhoangCach = ({ isOpen, onSave, onCancel, tourChietTinhId, dataSoKhach }) => {
    const { t, i18n } = useTranslation();
    const [soKhach, setSoKhach] = useState(null);
    const [tenMau, setTenMau] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const [dataLoaiKhachId, setDataLoaiKhachId] = useState([]);
    const [error, setError] = useState();
    const [dmSoCho, setDmSoCho] = useState([]);
    // const [dataSoCho, setDataSoCho] = useState([]);
    const [checkEdit, setCheckEdit] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    // const [checkEditToiDa, setCheckEditToiDa] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    useEffect(() => {
        setCheckEdit(false)
        // setDataSoCho(
        //     dataSoKhach
        //         ? dataSoKhach.map((soc) => {
        //               return { value: soc.dmSoChoId, label: soc.dmSoCho };
        //           })
        //         : []
        // );
        if (tourChietTinhId !== 0) {
            let data = {
                tourChietTinhId: tourChietTinhId,
            };
            ChietTinhService.getQuanLyKhoangKhach(data)
                .then(function (response) {
                    if (response.status === 200) {
                        let data = response.data.data;
                        let dataLst = [];
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                stt: i + 1,
                                id: data[i].id,
                                tourChietTinhId: data[i].tourChietTinhId,
                                dmLoaiKhachId: data[i].dmLoaiKhachId,
                                dmSoChoId: data[i].dmSoChoId,
                                soKhachToiThieu: data[i].soKhachToiThieu,
                                soKhachToiDa: data[i].soKhachToiDa,
                                foc: data[i].foc,
                            });
                        }
                        setDataTable(dataLst);
                    }
                })
                .catch(function (response) {
                    console.log("Error response: ====" + response);
                    if (response.status === 401) {
                    }
                });
        }
    }, [dataSoKhach, tourChietTinhId, isOpen]);
    const rows = [...dataTable, { id: "SUBTOTAL" }];
    useEffect(() => {
        let data = {};
        CategoryService.getDmLoaiKhach(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDataLoaiKhachId(data);
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
        getDmSoCho();
    }, []);
    const getDmSoCho = (e) => {
        otoService
            .getDmSoCho()
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.data;
                    let dmSoCho = [];
                    data.forEach((item) => {
                        if (item.hieu_luc) {
                            dmSoCho.push({
                                value: item.id,
                                label: item.so_cho_str,
                            });
                        }
                    });
                    setDmSoCho(dmSoCho);
                }
            })
            .catch((response) => {
                console.log("Error response: ====" + response);
            });
    };
    const onFinish = (e) => {
        e.preventDefault();
        var err = [];
        for (let i = 0; i < dataTable.length; i++) {
            const dt = dataTable[i];
            if (!dt.dmLoaiKhachId || !dt.soKhachToiThieu || !dt.dmSoChoId) {
                err.push(t("chuaNhapDuTruong"));
                break;
            } else {
                for (let j = i + 1; j < dataTable.length; j++) {
                    if (
                        dataTable[j].soKhachToiThieu === dt.soKhachToiThieu &&
                        dataTable[j].soKhachToiDa === dt.soKhachToiDa
                    ) {
                        err.push(t("trungKhoangKhach") + " " + (i + 1) + " và " + (j + 1));
                        break;
                    }
                    // debugger;
                    if (dataTable[j].soKhachToiThieu === dt.soKhachToiDa) {
                        err.push(
                            t("trungKhachToiThieuHoacToiDa") + " " +
                            (i + 1) +
                            " và " +
                            (j + 1)
                        );
                        break;
                    }
                }
                if (dt.foc >= dt.soKhachToiThieu) {
                    err.push(t("checkSoKhachFOC"));
                    break;
                }
            }
        }
        if (err.length < 1) {
            for (let k = 0; k < dataTable.length; k++) {
                for (let h = k + 1; h < dataTable.length; h++) {
                    const { soKhachToiThieu: minI, soKhachToiDa: maxI, stt: sttI } = dataTable[k];
                    const { soKhachToiThieu: minJ, soKhachToiDa: maxJ, stt: sttJ } = dataTable[h];

                    // Check if ranges overlap
                    if (minI <= maxJ && minJ <= maxI) {
                        err.push(t("error") + `: stt ${sttI} và stt ${sttJ} bị lồng số khách.`);
                    }
                }
            }
        }
        // debugger;
        setError(err);
        if (err.length > 0) {
            return;
        }
        let data = {
            tourChietTinhId: tourChietTinhId,
            khoangKhachRequests: dataTable.map((dt) => {
                return {
                    id: isNumeric(dt.id) ? dt.id : 0,
                    dmLoaiKhachId: dt.dmLoaiKhachId,
                    soKhachToiThieu: dt.soKhachToiThieu,
                    soKhachToiDa: dt.dmLoaiKhachId === 1 ? 0 : dt.soKhachToiDa,
                    dmSoCho: dt.dmSoChoId,
                    foc: dt.foc,
                };
            }),
        };
        // console.log(JSON.stringify(data));
        onSave(data, false);
    };
    const isNumeric = (value) => {
        return /^-?\d+$/.test(value);
    };
    const handleCancels = (e) => {
        e.preventDefault();
        onCancel(false);
        setPopupOpen(false);
    };
    const handleCancel = (e) => {
        e.preventDefault();
        setPopupOpen(true);
    };
    const handleClear = () => { };
    const danhSachMau = () => { };
    const onChangeTrangThai = () => { };
    const onChangeLoaiKhach = (e, stt) => {
        let updatedList = dataTable.map((item) => {
            if (item.stt === stt) {
                return { ...item, dmLoaiKhachId: e.target.value };
            }
            return item; // else return unmodified item
        });
        setCheckEdit(true);
        // if (e.target.value === 2) {
        //     setCheckEditToiDa(true);
        // }else {
        //     setCheckEditToiDa(false)
        // }
        setDataTable(updatedList);
    };
    const onChangeLoaiSoCho = (e, stt) => {
        let updatedList = dataTable.map((item) => {
            if (item.stt === stt) {
                return { ...item, dmSoChoId: e.target.value };
            }
            return item; // else return unmodified item
        });
        setDataTable(updatedList);
    };
    const onChangeSoKhachToiDa = (e, stt) => {
        let updatedList = dataTable.map((item) => {
            if (item.stt === stt) {
                return { ...item, soKhachToiDa: e.target.value };
            }
            return item; // else return unmodified item
        });
        setDataTable(updatedList);
    };
    const createRandomRow = () => {
        setCheckEdit(false);
        // setCheckEditToiDa(false)
        return {
            stt: dataTable.length + 1,
            id: "NEW" + dataTable.length,
            dmLoaiKhachId: null,
            dmSoChoId: null,
            soKhachToiThieu: null,
            soKhachToiDa: null,
            foc: null,
        };
    };
    const handleAddRow = () => {
        setDataTable((prevRows) => [...prevRows, createRandomRow()]);
    };
    const handleDeleteRow = (row) => {
        if (dataTable.length === 0) {
            return;
        }
        let data = dataTable.filter((el) => el.stt !== row.stt);
        if (row.id !== 0) {
            let dataI = {
                tourQuanLyKhoangKhachId: row.id
            }
            ChietTinhService.deleteQuanLyKhoangKhach(dataI).then(function (response) {
                if (response.status === 200 && response.data.code === "00") {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.success").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                } else {
                    api["error"]({
                        message: t("stour"),
                        description: t("delete") + " " + t("noti.error").toLowerCase(),
                        duration: TIME_DURATION,
                    });
                }
            }).catch(function (response) {
                // console.log("Error response: ====" + response);
                api["error"]({
                    message: t("stour"),
                    description: t("delete") + " " + t("noti.error").toLowerCase(),
                    duration: TIME_DURATION,
                });
                if (response.status === 401) {
                }
            });
        }
        setDataTable(data);
    };

    const columns = [
        {
            field: "stt",
            headerName: t("stt"),
            width: 10,
            editable: true,
            align: "center",
            colSpan: ({ row }) => {
                if (row.id === "SUBTOTAL") {
                    return 2;
                }
                return undefined;
            },
            renderCell: ({ row, api }) => {
                if (row.id === "SUBTOTAL") {
                    return (
                        <Box sx={{ display: "flex", alignItems: "flex-end" }} onClick={handleAddRow}>
                            <AddIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                            <span style={{ marginBottom: "5px", fontSize: "15px" }}>{t("tour.themSoLuongKhach")}</span>
                        </Box>
                    );
                }else {
                    const index = api.getAllRowIds().indexOf(row.id);
                    return index + 1; // STT bắt đầu từ 1
                }
            },
        },
        {
            field: "dmLoaiKhachId",
            headerName: t("tour.loaiKhach"),
            width: 220,
            align: "center",
            renderCell: ({ value, row }) => {
                return (
                    <TextField
                        // label={t("tour.soKhachToiThieu")}
                        select
                        fullWidth
                        name="dmLoaiKhachId"
                        onChange={(e) => onChangeLoaiKhach(e, row.stt)}
                        value={value}
                    >
                        {dataLoaiKhachId.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.loai_khach}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            },
        },
        {
            field: "soKhachToiThieu",
            headerName: t("tour.soKhachToiThieu"),
            type: "number",
            width: 150,
            align: "center",
            editable: checkEdit,
            // renderCell: ({ value, row }) => {
            //     if (row.dmLoaiKhachId === 1 && row.soKhachToiDa !== undefined) {
            //         return row.soKhachToiDa;
            //     }
            // },
        },
        {
            field: "soKhachToiDa",
            headerName: t("tour.soKhachToiDa"),
            type: "number",
            width: 150,
            align: "center",
            renderCell: ({ value, row }) => {
                // if (row.dmLoaiKhachId === 1 && row.soKhachToiThieu !== undefined) {
                //     return row.soKhachToiThieu;
                // }
                return (
                    <TextField
                        fullWidth
                        disabled={row.dmLoaiKhachId !== 2}
                        name="soKhachToiDa"
                        onChange={(e) => onChangeSoKhachToiDa(e, row.stt)}
                        value={row.dmLoaiKhachId === 1 ? "" : value}
                    >
                    </TextField>
                );
            },
        },
        {
            field: "dmSoChoId",
            headerName: t("number"),
            description: t("number"),
            align: "center",
            width: 160,
            renderCell: ({ value, row }) => {
                if (row.id !== "SUBTOTAL") {
                    return (
                        <TextField
                            select
                            fullWidth
                            name="dmSoChoId"
                            onChange={(e) => onChangeLoaiSoCho(e, row.stt)}
                            value={value}
                        >
                            {/*{dataSoCho.length > 0*/}
                            {/*    ? dataSoCho.map((option) => (*/}
                            {/*          <MenuItem key={option.value} value={option.value}>*/}
                            {/*              {option.label}*/}
                            {/*          </MenuItem>*/}
                            {/*      ))*/}
                            {/*    : dmSoCho.map((option) => (*/}
                            {/*          <MenuItem key={option.value} value={option.value}>*/}
                            {/*              {option.label}*/}
                            {/*          </MenuItem>*/}
                            {/*      ))}*/}
                            {dmSoCho.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    );
                } else return null;
            },
        },
        {
            field: "foc",
            headerName: t("hotel.roomClass.foc"),
            description: t("hotel.roomClass.foc"),
            type: "number",
            editable: true,
            align: "center",
            width: 120,
        },
        {
            field: "action",
            headerName: t("action"),
            description: t("action"),
            align: "center",
            sortable: false,
            width: 100,
            renderCell: (record) => {
                if (record.row.id !== "SUBTOTAL") {
                    return (
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDeleteRow(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    );
                }
            },
        },
    ];
    const processRowUpdate = (row) => {
        setDataTable(
            dataTable.map((dt) => {
                if (dt.id === row.id) return row;
                else return dt;
            })
        );
    };

    return (
        <>
            <Popconfirm
                style={{ marginLeft: "1000px", display: "block" }}
                title={t("closePopup")}
                description={t("areYouConfirmClosePopup")}
                placement="topLeft"
                okText={t("yes")}
                cancelText={t("no")}
                onConfirm={handleCancels}
                open={popupOpen}
            />

            <Modal
                title={t("tour.quanLyKhoangCach")}
                open={isOpen}
                onOk={onFinish}
                onCancel={handleCancel}
                footer={null}
                width={1000}
                height={700}
            >
                <Divider />
                <Box component="form" noValidate>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={5}>
                            <TextField
                                label={t("tour.soKhach")}
                                select
                                fullWidth
                                name="soKhach"
                                onChange={(e) => setSoKhach(e.target.value)}
                                value={soKhach}
                            >
                                {dataTable.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.dmLoaiKhachId === 2 && option.soKhachToiThieu !== option.soKhachToiDa
                                            ? option.soKhachToiThieu + " - " + option.soKhachToiDa
                                            : option.soKhachToiThieu}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Col>
                        {/*<Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>*/}
                        {/*<Col xs={12} sm={12} md={12} lg={12} xl={4}>*/}
                        {/*    <TextField*/}
                        {/*        label={t("tour.soKhachToiDa")}*/}
                        {/*        select*/}
                        {/*        fullWidth*/}
                        {/*        name="soKhachToiDa"*/}
                        {/*        onChange={e => setSoKhachToiDa(e.target.value)}*/}
                        {/*        value={soKhachToiDa}*/}
                        {/*    >*/}
                        {/*        {dataTable.map((option) => (*/}
                        {/*            <MenuItem key={option.id} value={option.id}>*/}
                        {/*                {option.soKhachToiDa}*/}
                        {/*            </MenuItem>*/}
                        {/*        ))}*/}
                        {/*    </TextField>*/}
                        {/*</Col>*/}
                        <Col xs={12} sm={12} md={12} lg={12} xl={1}></Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                            <Button
                                sx={{ marginTop: "10px" }}
                                variant="contained"
                                // onClick={() => onSearch()}
                                type="submit"
                                startIcon={<SearchIcon />}
                            >
                                {t("search")}
                            </Button>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={4}></Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={4}>
                            <Button sx={{ marginTop: "10px" }} variant="outlined" onClick={danhSachMau} type="submit">
                                {t("tour.danhSachMau")}
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: "10px" }}></Row>
                    <DataGrid
                        autoheight
                        rows={
                            soKhach
                                ? rows.filter(
                                    (r) =>
                                        r.id === soKhach ||
                                        r.id.toString().indexOf("NEW") !== -1 ||
                                        r.id.toString().indexOf("SUBTOTAL") !== -1
                                )
                                : rows
                        }
                        columns={columns}
                        processRowUpdate={processRowUpdate}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
                        sx={{
                            "& .MuiDataGrid-cell": {
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-row": {
                                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                            },
                            "& .MuiDataGrid-columnHeader": {
                                borderRight: "1px solid rgba(224, 224, 224, 1)",
                                backgroundColor: "#e2e2e2",
                            },
                            "& .MuiDataGrid-columnHeaderTitle ": {
                                fontWeight: "bold",
                            },
                        }}
                    />
                    {error ? error.map((e) => <Text type="danger">{e}</Text>) : <></>}
                    <Row style={{ marginTop: 6 }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                            <FormControlLabel
                                style={{ marginTop: "14px" }}
                                label={t("tour.luuMau")}
                                control={<Switch style={{ marginLeft: "10px" }} onChange={onChangeTrangThai} />}
                                labelPlacement="start"
                            />
                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                sx={{
                                    marginBottom: 2,
                                }}
                                fullWidth
                                label={t("tour.tenMau")}
                                multiline
                                rows={1}
                                value={tenMau}
                                onChange={(e) => setTenMau(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 6 }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={12}></Col>
                        <Col xs={24} sm={24} md={24} lg={18} xl={10}>
                            <Space>
                                <Button variant="contained" onClick={onFinish} type="submit" startIcon={<SaveIcon />}>
                                    {t("action.ok")}
                                </Button>
                                <Button
                                    style={{
                                        backgroundColor: "#898989",
                                        color: "white",
                                    }}
                                    key="reset"
                                    startIcon={<RestartAltIcon />}
                                    onClick={(e) => handleClear(e)}
                                >
                                    {t("action.reset")}
                                </Button>
                                <Popconfirm
                                    title={t("closePopup")}
                                    description={t("areYouConfirmClosePopup")}
                                    okText={t("yes")}
                                    cancelText={t("no")}
                                    onConfirm={handleCancels}
                                >
                                    <Button variant="outlined" startIcon={<DeleteIcon />} key="clear">
                                        {t("action.cancel")}
                                    </Button>
                                </Popconfirm>
                            </Space>
                        </Col>
                    </Row>
                </Box>
            </Modal>
        </>
    );
};
export default ModalQuanLyKhoangCach;
