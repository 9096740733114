import {Popconfirm,  Typography} from "antd";
import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import {MenuItem} from "@mui/material";
import {DeleteOutlined} from "@ant-design/icons";
import CategoryService from "../../../../../api/category.service";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import {DataGrid, enUS, viVN} from "@mui/x-data-grid";


const TableModalLT = ({keyNum, setDataEdit, dataS, dataSum }) => {
    const { t, i18n } = useTranslation();
    const [checkEdit, setCheckEdit] = useState(false);
    const [dataLoaiKhachId, setDataLoaiKhachId] = useState([]);
    //const [dataSource, setDataSource] = useState(dataS || []); // Khởi tạo với dataS nếu có
    const [dataSource, setDataSource] = useState(() => dataS || []);

    useEffect(() => {
        // @ts-ignore
        if (dataS && dataSource.length === 0) {
            setDataSource(dataS); // Cập nhật dataSource khi dataS thay đổi
        }
    }, [dataS]); // Chạy lại khi dataS thay đổi
    useEffect(() => {
        let data = {};
        CategoryService.getDmLoaiKhach(data)
            .then(function (response) {
                if (response.status === 200) {
                    let data = response.data.data;
                    setDataLoaiKhachId(data);
                }
            })
            .catch(function (response) {
                if (response.status === 401) {
                }
            });
    }, []);

    const handleDeleteRow = (row) => {
        setDataSource(dataSource.filter((item) => item.stt !== row.stt));
    }
    const rows = [...dataSource, { id: "SUBTOTAL" }];
    const onChangeLoaiKhach = (e, stt) => {
        setDataSource(prevDataSource =>
            prevDataSource.map(item =>
                item.stt === stt
                    ? { ...item, dmLoaiKhachId: e.target.value }
                    : item
            )
        );
        setCheckEdit(true)
    };


    const onChangeSoKhachToiDa = (e, stt) => {
        setDataSource(prevData =>
            prevData.map(item =>
                item.stt === stt ? { ...item, soKhachToiDa: e.target.value } : item
            )
        );
    };
    const createRandomRow = (stt) => {
        setCheckEdit(false);
        // setCheckEditToiDa(false)
        return {
            stt: stt,
            id: "NEW" + Date.now(),
            dmLoaiKhachId: "",
            giaBan: null,
            soKhachToiThieu: null,
            soKhachToiDa: null,
            giaNet: null,
        };
    };
    const handleAddRow = () => {
        setDataSource((prevRows) => {
            console.log("Previous Rows:", prevRows);
            const updatedRows = prevRows.map((row, index) => ({ ...row, stt: index + 1 })); // Cập nhật lại stt
            return [...updatedRows, createRandomRow(updatedRows.length + 1)];
        });
    };

    const columns = [
        {
            field: "stt",
            headerName: t("stt"),
            width: 10,
            editable: true,
            align: "center",
            colSpan: ({ row }) => {
                if (row.id === "SUBTOTAL") {
                    return 2;
                }
                return undefined;
            },
            renderCell: ({ row, api }) => {
                if (row.id === "SUBTOTAL") {
                    return (
                        <Box sx={{ display: "flex", alignItems: "flex-end" }} onClick={handleAddRow}>
                            <AddIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
                            <span style={{ marginBottom: "5px", fontSize: "15px" }}>{t("tour.themSoLuongKhach")}</span>
                        </Box>
                    );
                }else {
                    const index = api.getAllRowIds().indexOf(row.id);
                    return index + 1; // STT bắt đầu từ 1
                }
            },
        },
        {
            field: "dmLoaiKhachId",
            headerName: t("tour.loaiKhach"),
            width: 230,
            align: "center",
            renderCell: ({ value, row }) => {
                return (
                    <TextField
                        // label={t("tour.soKhachToiThieu")}
                        select
                        fullWidth
                        name="dmLoaiKhachId"
                        onChange={(e) => onChangeLoaiKhach(e, row.stt)}
                        value={value ?? ""}
                    >
                        {dataLoaiKhachId.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.loai_khach}
                            </MenuItem>
                        ))}
                    </TextField>
                );
            },
        },
        {
            field: "soKhachToiThieu",
            headerName: t("tour.soKhachToiThieu"),
            type: "number",
            align: "center",
            editable: checkEdit,
            width: 200,
            // renderCell: ({ value, row }) => {
            //     if (row.dmLoaiKhachId === 1 && row.soKhachToiDa !== undefined) {
            //         return row.soKhachToiDa;
            //     }
            // },
        },
        {
            field: "soKhachToiDa",
            headerName: t("tour.soKhachToiDa"),
            type: "number",
            align: "center",
            width: 200,
            // editable: checkEdit,
            // editable: true,
            renderCell: ({ value, row }) => {
                // if (row.dmLoaiKhachId === 1 && row.soKhachToiThieu !== undefined) {
                //     return row.soKhachToiThieu;
                // }
                return (
                    <TextField
                        fullWidth
                        disabled={row.dmLoaiKhachId !== 2}
                        name="soKhachToiDa"
                        onChange={(e) => onChangeSoKhachToiDa(e, row.stt)}
                        value={row.dmLoaiKhachId === 1 ? "" : value}
                    >
                    </TextField>
                );
            },
        },
        {
            field: "giaNet",
            headerName: t("hotel.priceClass.giaNet"),
            description: t("hotel.priceClass.giaNet"),
            align: "center",
            editable: true,
            width: 160,

            // Hiển thị số tiền có định dạng
            renderCell: ({ value }) => {
                if (value == null || value === "") return ""; // Nếu không có giá trị, hiển thị "0"
                return new Intl.NumberFormat("en-US").format(value); // Định dạng số: 2,000,000
            },


        }

        ,
        {
            field: "giaBan",
            headerName: t("hotel.priceClass.giaBan"),
            description: t("hotel.priceClass.giaBan"),
            editable: true,
            align: "center",
            width: 160,
            // Hiển thị số tiền có định dạng
            renderCell: ({ value }) => {
                if (value == null || value === "") return ""; // Nếu không có giá trị, hiển thị "0"
                return new Intl.NumberFormat("en-US").format(value); // Định dạng số: 2,000,000
            },
        },
        {
            field: "action",
            headerName: t("action"),
            description: t("action"),
            align: "center",
            sortable: false,
            width: 100,
            renderCell: (record) => {
                if (record.row.id !== "SUBTOTAL") {
                    return (
                        <Typography.Link>
                            <Popconfirm
                                title={t("deleteTheTask")}
                                description={t("areYouConfirm")}
                                okText={t("delete")}
                                cancelText={t("huy")}
                                onConfirm={() => handleDeleteRow(record.row)}
                            >
                                <DeleteOutlined />
                            </Popconfirm>
                        </Typography.Link>
                    );
                }
            },
        },
    ];
    const processRowUpdate = (row) => {
        setDataSource(
            dataSource.map((dt) => {
                if (dt.id === row.id) return row;
                else return dt;
            })
        );
        //let data =[];
        //data.push()
        setDataEdit({
            key: keyNum,
            data: dataSource.map((dt) => {
                if (dt.id === row.id) return row;
                else return dt;
            })
        });
    };
    return (
        <>
            <DataGrid
                autoheight
                rows={rows}
                columns={columns}
                processRowUpdate={processRowUpdate}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[10]}
                localeText={i18n.language === "vi" ? viVN.components.MuiDataGrid.defaultProps.localeText : enUS}
                sx={{
                    "& .MuiDataGrid-cell": {
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiDataGrid-row": {
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiDataGrid-columnHeader": {
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                        backgroundColor: "#e2e2e2",
                    },
                    "& .MuiDataGrid-columnHeaderTitle ": {
                        fontWeight: "bold",
                    },
                }}
            />
        </>
    );
};
export default TableModalLT;
