import React, { useEffect, useState } from "react";
import {
    Space,
    Input,
    Modal,
    Divider,
    Form,
    Row,
    Col,
    DatePicker,
    Card,
    Switch,
    Affix,
} from "antd";
import { useTranslation } from "react-i18next";
import { MinusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { API_BASE_URL, data_getDM } from "../../../../../constants";
import "../../../../../assets/css/supplier/res/dvCc/modal.css";
import moment from "moment";
import authHeader from "../../../../../api/auth_header";
import TableModalGP from "./TableModalGP";
import { Box, Button } from "@mui/material";
import RestartAlt from "@mui/icons-material/RestartAlt";
import Save from "@mui/icons-material/Save";
import Delete from "@mui/icons-material/Delete";
import InputMui from "../../../../layout/InputMui";
import SingleSelectMui from "../../../../layout/SingleSelectMui";

const { RangePicker } = DatePicker;

const ModalPR = ({ isOpen, handleCancel, handleFinish, dvId }) => {
    const [open, setOpen] = useState(isOpen);
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const [tiGia, setTiGia] = useState([]);
    const [dataSource, setDataSource] = useState();
    const [id, setId] = useState("");
    const [maDichVu, setMaDichVu] = useState("");
    const [dmTyGiaId, setDmTyGiaId] = useState(1);
    const [tenDichVu, setTenDichVu] = useState("");
    const [ghiChu, setGhiChu] = useState("");

    const [maDichVuErr, setMaDichVuErr] = useState(false);
    const [tenDichVuErr, setTenDichVuErr] = useState(false);
    const [timeErr, setTimeErr] = useState(false);

    useEffect(() => {
        axios({
            method: "post",
            url: API_BASE_URL + "category/getDmTyGia",
            data: data_getDM,
            headers: authHeader(),
        })
            .then(function (response) {
                console.log("Response: ====" + response.status);
                if (response.status === 200) {
                    console.log(response.data.data);
                    let data = response.data.data;
                    let dataLst = [];
                    if (data.length > 0) {
                        for (let i = 0; i < data.length; i++) {
                            dataLst.push({
                                value: data[i].id,
                                label: data[i].loai_tien,
                            });
                        }
                        setTiGia(dataLst);
                    }
                }
            })
            .catch(function (response) {
                console.log("Error response: ====" + response);
                if (response.status === 401) {
                }
            });
    }, []);

    useEffect(() => {
        if (!isOpen) {
            form.resetFields();
            handleClear();
        }
    }, [isOpen]);

    const onFinish = (values) => {
        setTimeErr(false);
        setMaDichVuErr(false);
        setTenDichVuErr(false);
        var error = false;
        if (!maDichVu) {
            setMaDichVuErr(true);
            error = true;
        }
        if (!tenDichVu) {
            setTenDichVuErr(true);
            error = true;
        }
        var timeErr = [];
        var tranxDvNhaHangTimeRequests = [];
        if (values.items) {
            for (let i = 0; i < values.items.length; i++) {
                const element = values.items[i];
                console.log(element, "element");
                var time = element.rangeTime ? false : true;
                timeErr.push({
                    time: time,
                });
                if (!time) {
                    if (dataSource) {
                        console.log(dataSource, "vao element");
                        var index = dataSource.findIndex(
                            (item) => item.key === i
                        );
                        tranxDvNhaHangTimeRequests.push({
                            nccDichVuNhId: element.nccDichVuNhId,
                            id: element.id,
                            tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                            denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                            fitNgayThuongNet:
                                dataSource[index].fit.fitNgayThuongNet,
                            fitNgayThuongGiaBan:
                                dataSource[index].fit.fitNgayThuongGiaBan,
                            fitCuoiTuanNet:
                                dataSource[index].fit.fitCuoiTuanNet,
                            fitCuoiTuanGiaBan:
                                dataSource[index].fit.fitCuoiTuanGiaBan,
                            trangThaiBooking:
                                element.trangThaiBooking ||
                                element.trangThaiBooking === undefined,
                            foc: element.foc,
                            dieuKienFoc: element.dieuKienFoc,
                        });
                    } else {
                        tranxDvNhaHangTimeRequests.push({
                            nccDichVuNhId: element.nccDichVuNhId,
                            id: element.id,
                            tuNgay: element.rangeTime[0].format("DD/MM/YYYY"),
                            denNgay: element.rangeTime[1].format("DD/MM/YYYY"),
                            trangThaiBooking:
                                element.trangThaiBooking ||
                                element.trangThaiBooking === undefined,
                            foc: element.foc,
                            dieuKienFoc: element.dieuKienFoc,
                        });
                    }
                } else error = true;
            }
        } else error = true;
        setTimeErr(timeErr);
        if (error) return;
        else {
            let data = {
                id: id,
                nccThongTinChungId: dvId,
                maDichVu: maDichVu,
                tenDichVu: tenDichVu,
                dmTyGiaId: dmTyGiaId ? dmTyGiaId : 1,
                loaiTien: tiGia[dmTyGiaId] ? tiGia[dmTyGiaId].label : "",
                ghiChu: ghiChu ? ghiChu : "",
                tranxDvNhaHangTimeRequests: tranxDvNhaHangTimeRequests,
            };
            handleFinish(open, data);
        }
    };

    const handleCancels = () => {
        form.resetFields();
        handleClear();
        handleCancel();
        setOpen(false);
    };

    const dataRender = () => {
        let html;
        html = (
            <>
                <Form.Item name="id" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="nccThongTinChungId" hidden>
                    <Input />
                </Form.Item>

                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.List name="items">
                            {(fields, { add, remove }) => (
                                <div
                                    style={{
                                        display: "flex",
                                        rowGap: 16,
                                        flexDirection: "column",
                                    }}
                                >
                                    {fields.map(
                                        ({ key, name, ...restField }) => (
                                            <Card
                                                size="large"
                                                key={key}
                                                // extra={
                                                //     <CloseOutlined
                                                //         onClick={() => {
                                                //             remove(field.name);
                                                //         }}
                                                //     />
                                                // }
                                            >
                                                <Row>
                                                    <Col
                                                        xs={14}
                                                        sm={14}
                                                        md={14}
                                                        lg={14}
                                                        xl={14}
                                                    >
                                                        <Form.Item
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                "rangeTime",
                                                            ]}
                                                            label={
                                                                t(
                                                                    "hotel.priceRoom.applicationTime"
                                                                ) + " *"
                                                            }
                                                            {...formItemLayoutDate}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        t(
                                                                            "validate"
                                                                        ) +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <RangePicker
                                                                style={{
                                                                    width: "100%",
                                                                }}
                                                                placeholder={[
                                                                    t("toDate"),
                                                                    t(
                                                                        "fromDate"
                                                                    ),
                                                                ]}
                                                                disabledDate={(
                                                                    current
                                                                ) => {
                                                                    let customDate =
                                                                        moment().format(
                                                                            "DD-MM-YYYY"
                                                                        );
                                                                    return (
                                                                        current &&
                                                                        current <
                                                                            moment(
                                                                                customDate,
                                                                                "DD-MM-YYYY"
                                                                            )
                                                                    );
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        xs={5}
                                                        sm={5}
                                                        md={5}
                                                        lg={5}
                                                        xl={5}
                                                    ></Col>
                                                    <Col
                                                        xs={5}
                                                        sm={5}
                                                        md={5}
                                                        lg={5}
                                                        xl={2}
                                                    >
                                                        {fields.length > 0 ? (
                                                            <MinusCircleOutlined
                                                                className="dynamic-delete-button"
                                                                onClick={() =>
                                                                    remove(name)
                                                                }
                                                            />
                                                        ) : null}
                                                    </Col>
                                                </Row>

                                                <Space
                                                    direction="vertical"
                                                    size="small"
                                                    style={{
                                                        display: "flex",
                                                        marginTop: "15px",
                                                    }}
                                                >
                                                    <Row>
                                                        <TableModalGP
                                                            style={{
                                                                width: "100%",
                                                            }}
                                                            keyNum={key}
                                                            setDataEdit={
                                                                setDataSource
                                                            }
                                                            dataEdit={
                                                                dataSource
                                                            }
                                                        />
                                                    </Row>

                                                    <Row>
                                                        <Col
                                                            xs={10}
                                                            sm={10}
                                                            md={10}
                                                            lg={10}
                                                            xl={10}
                                                        >
                                                            <Form.Item
                                                                {...restField}
                                                                name={[
                                                                    name,
                                                                    "dieuKienFoc",
                                                                ]}
                                                                rules={[
                                                                    {
                                                                        required: false,
                                                                        message:
                                                                            t(
                                                                                "validate"
                                                                            ) +
                                                                            " " +
                                                                            t(
                                                                                "hotel.roomClass.conditionFoc"
                                                                            ).toLowerCase(),
                                                                    },
                                                                ]}
                                                            >
                                                                <InputMui
                                                                    label={t(
                                                                        "hotel.roomClass.conditionFoc"
                                                                    )}
                                                                    sx={{
                                                                        marginTop: 2,
                                                                    }}
                                                                    fullWidth
                                                                ></InputMui>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col
                                                            xs={1}
                                                            sm={1}
                                                            md={1}
                                                            lg={1}
                                                            xl={1}
                                                        ></Col>
                                                        <Col
                                                            xs={2}
                                                            sm={2}
                                                            md={2}
                                                            lg={2}
                                                            xl={2}
                                                            style={{
                                                                marginTop:
                                                                    "30px",
                                                            }}
                                                        >
                                                            {t("set")}
                                                        </Col>
                                                        <Col
                                                            xs={4}
                                                            sm={4}
                                                            md={4}
                                                            lg={4}
                                                            xl={4}
                                                        >
                                                            <Form.Item
                                                                {...restField}
                                                                name={[
                                                                    name,
                                                                    "foc",
                                                                ]}
                                                                // label={t("hotel.roomClass.foc")}
                                                                rules={[
                                                                    {
                                                                        required: false,
                                                                        message:
                                                                            t(
                                                                                "validate"
                                                                            ) +
                                                                            " " +
                                                                            t(
                                                                                "hotel.roomClass.foc"
                                                                            ).toLowerCase(),
                                                                    },
                                                                ]}
                                                            >
                                                                <InputMui
                                                                    sx={{
                                                                        marginTop: 2,
                                                                    }}
                                                                    fullWidth
                                                                ></InputMui>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col
                                                            xs={1}
                                                            sm={1}
                                                            md={1}
                                                            lg={1}
                                                            xl={1}
                                                        ></Col>
                                                        <Col
                                                            xs={2}
                                                            sm={2}
                                                            md={2}
                                                            lg={2}
                                                            xl={2}
                                                            style={{
                                                                marginTop:
                                                                    "30px",
                                                            }}
                                                        >
                                                            {t(
                                                                "hotel.roomClass.foc"
                                                            )}
                                                        </Col>
                                                    </Row>

                                                    <Row
                                                        style={{
                                                            marginTop: "15px",
                                                        }}
                                                    >
                                                        <Form.Item
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                "trangThaiBooking",
                                                            ]}
                                                            label={
                                                                t("status") +
                                                                " " +
                                                                "booking"
                                                            }
                                                            rules={[
                                                                {
                                                                    required: false,
                                                                    message:
                                                                        t(
                                                                            "validate"
                                                                        ) +
                                                                        " " +
                                                                        t(
                                                                            "hotel.priceRoom.applicationTime"
                                                                        ).toLowerCase(),
                                                                },
                                                            ]}
                                                        >
                                                            <Switch
                                                                defaultChecked={
                                                                    true
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Row>
                                                </Space>
                                            </Card>
                                        )
                                    )}
                                    <Row>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                            <Button onClick={() => add()} block>
                                                {t("add") +
                                                    " " +
                                                    t("time").toLowerCase()}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Form.List>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <InputMui
                            label={t("notes")}
                            value={ghiChu ? ghiChu : ""}
                            fullWidth
                            // multiline
                            // rows={5}
                            onChange={(e) => setGhiChu(e.target.value)}
                        ></InputMui>
                    </Col>
                </Row>
            </>
        );
        return html;
    };
    const isEmpty = (record) => {
        for (const prop in record) {
            if (Object.hasOwn(record, prop)) {
                return false;
            }
        }

        return true;
    };
    const formItemLayoutDate = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
        },
    };
    const handleClear = () => {
        form.resetFields();
        setMaDichVu("");
        setDmTyGiaId(1);
        setTenDichVu("");
        setGhiChu("");
    };
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        setTimeErr(false);
        setMaDichVuErr(false);
        setTenDichVuErr(false);

        if (!maDichVu) {
            setMaDichVuErr(true);

        }
        if (!tenDichVu) {
            setTenDichVuErr(true);

        }
        console.log("onFinishFailed:", values);
    };
    return (
        <div>
            <Modal
                title={
                    !isEmpty
                        ? t("edit") +
                          " " +
                          t("res.serviceProvided").toLowerCase()
                        : t("add") +
                          " " +
                          t("res.serviceProvided").toLowerCase()
                }
                open={isOpen}
                // onOk={onFinish}
                // onCancel={handleCancels}
                onCancel={handleCancels}
                footer={null}
                width={1200}
                // bodyStyle={{ background: "#F5F5F5" }}
            >
                <Divider />
                <Box component="form" noValidate>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <InputMui
                                        label={t("res.code") + " *"}
                                        value={maDichVu ? maDichVu : ""}
                                        fullWidth
                                        onChange={(e) =>
                                            setMaDichVu(e.target.value)
                                        }
                                        error={maDichVuErr}
                                        helperText={
                                            maDichVuErr
                                                ? t("validate") +
                                                  " " +
                                                  t("res.code").toLowerCase()
                                                : ""
                                        }
                                    ></InputMui>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <InputMui
                                        label={t("res.serviceProvided") + " *"}
                                        value={tenDichVu ? tenDichVu : ""}
                                        fullWidth
                                        onChange={(e) =>
                                            setTenDichVu(e.target.value)
                                        }
                                        error={tenDichVuErr}
                                        helperText={
                                            tenDichVuErr
                                                ? t("validate") +
                                                  " " +
                                                  t(
                                                      "res.serviceProvided"
                                                  ).toLowerCase()
                                                : ""
                                        }
                                    ></InputMui>

                        </Col>
                        <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                        <Col xs={11} sm={11} md={11} lg={11} xl={11}>
                                    <SingleSelectMui
                                        value={dmTyGiaId}
                                        fullWidth
                                        options={tiGia}
                                        onChange={(e) =>
                                            setDmTyGiaId(e.target.value)
                                        }
                                        label={t("exchangeRate.currency")}
                                    >
                                    </SingleSelectMui>
                        </Col>
                    </Row>
                </Box>
                <Form
                    form={form}
                    noValidate
                    onFinishFailed={onFinishFailed}
                    onFinish={onFinish}
                    colon={false}
                    layout="vertical"
                    initialValues={{
                        items: [{}],
                    }}
                >
                    {dataRender()}
                    <Form.Item
                        wrapperCol={{
                            span: 12,
                            offset: 12,
                        }}
                    >
                        <Affix offsetBottom={50}>
                            <div
                                style={{
                                    textAlign: "center",
                                    color: "#fff",
                                    height: 60,
                                    paddingInline: 50,
                                    lineHeight: "60px",
                                    backgroundColor: "rgba(150, 150, 150, 0.2)",
                                    borderRadius: "4px",
                                }}
                            >
                                <Space>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        startIcon={<Save />}
                                    >
                                        {t("action.ok")}
                                    </Button>
                                    <Button
                                        sx={{
                                            backgroundColor: "#898989",
                                            color: "white",
                                        }}
                                        key="reset"
                                        startIcon={<RestartAlt />}
                                        onClick={(e) => handleClear(e)}
                                    >
                                        {t("action.reset")}
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        startIcon={<Delete />}
                                        onClick={handleCancels}
                                        key="clear"
                                    >
                                        {t("action.cancel")}
                                    </Button>
                                </Space>
                            </div>
                        </Affix>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default ModalPR;
