import axios from "axios";
import authHeader from "../../auth_header";
import { API_BASE_URL } from "../../../constants";

const getCauHinhVeThuyenTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/layCauHinhVeThuyenTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const updateCauHinhVeThuyenTheoMap = (data) => {
  return axios.post(
    API_BASE_URL + "tour/chiettinh/updateCauHinhVeThuyenTheoMapCTCT",
    data,
    {
      headers: authHeader(),
    }
  );
};

const saveCauHinhVeThuyen = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/saveCauHinhVeThuyen", data, {
    headers: authHeader(),
  });
};

const getQuocGia = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachQuocGia", data, {
    headers: authHeader(),
  });
};

const getTinhTP = (data) => {
  return axios.post(API_BASE_URL + "category/danhSachTinhThanhPho", data, {
    headers: authHeader(),
  });
};

const getNccVeThuyen = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/getNccVeThuyen", data, {
    headers: authHeader(),
  });
};

const xoaCauHinhVeThuyen = (data) => {
  return axios.post(API_BASE_URL + "tour/chiettinh/xoaCauHinhVeThuyen", data, {
    headers: authHeader(),
  });
};

export default {
  getCauHinhVeThuyenTheoMap,
  updateCauHinhVeThuyenTheoMap,
  saveCauHinhVeThuyen,
  getQuocGia,
  getTinhTP,
  getNccVeThuyen,
  xoaCauHinhVeThuyen,
};
